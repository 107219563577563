import { Button, Icon, IconSize, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import CommandArguments from 'pages/ops/RTI/Operate/components/CommandArguments';
import DndList from 'components/DndList';
import { ops } from 'constants/mixpanelAnalytics';
import { assoc, find, get } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { swapArrayItems } from 'utils/common/CommonUtils';
import parseRefDefinitions from 'pages/ops/RTI/Operate/utils/rtiCommands/parseRefDefinitions';
import { v1 as uuid } from 'uuid';
import s from './index.module.scss';
import LibraryNewTransformer from './LibraryNewTransformer';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import type {
  GatewayRadioConfig,
  RadioTransformer,
  Schema,
} from 'api/gateway/types';

interface IProps {
  title: string;
  schemas: Schema[];
  payload: RadioTransformer[];
  radioToEdit: GatewayRadioConfig | undefined;
  transformerType: 'uplink' | 'downlink';
  missionId: number | undefined;
  handleChangeGatewaySettings: (radio: GatewayRadioConfig) => void;
  allAvailableTransformersSchemas: Schema[];
}

const DEFAULT_TRANSFORMER = 'Transformer';

const LibraryRadioTransformers = ({
  title,
  schemas,
  payload,
  handleChangeGatewaySettings,
  radioToEdit,
  transformerType,
  missionId,
  allAvailableTransformersSchemas,
}: IProps) => {
  const [parsedSchemas, setParsedSchemas] = useState<Schema[]>([]);

  const [newTransformer, setNewTransformer] = useState<RadioTransformer>();

  const { sendInfo } = useAnalytics();

  const handlePayloadChange = (index: number) => (path: string, value: any) => {
    const newPayload = assoc(`[${index}].options.${path}`, value, payload);

    saveChanges(newPayload);
  };

  const parseSchemas = (schemas: Schema[]) => {
    return schemas.map((schema) => ({
      ...schema,
      schema: parseRefDefinitions({
        ...schema.schema,
      }),
    })) as Schema[];
  };

  const renderCollapsedItem = (item: any, index: number) => {
    const trans = get(`[${index}]`, payload);
    const transformer = get('transformer', trans);
    const properties = find(['name', transformer], parsedSchemas)?.schema;

    if (!properties) {
      return <NonIdealState icon="issue" title="There are no options!" />;
    }

    return (
      <CommandArguments
        commandProperties={properties}
        commandPayload={trans.options}
        handleCommandPayloadChange={handlePayloadChange(index)}
      />
    );
  };

  const saveChanges = (newPayload: RadioTransformer[]) => {
    if (!radioToEdit) {
      return;
    }

    handleChangeGatewaySettings({
      ...radioToEdit,
      transformers: {
        ...radioToEdit.transformers,
        [transformerType]: newPayload,
      },
    });

    sendInfo({
      type: ops.LIBRARY.TRANSFORMER.CHANGE,
      action: 'Change',
      item: 'Library transform',
      module: 'OPS',
      additionalParams: { mission: missionId },
    });
  };

  const handleSaveClick = () => {
    if (!newTransformer) {
      return;
    }

    const newPayload = [...(payload || []), newTransformer];

    setNewTransformer(undefined);
    saveChanges(newPayload);
  };

  const handleDeleteClick = (index: number) => {
    const newPayload = [...payload];
    newPayload.splice(index, 1);
    saveChanges(newPayload);
  };

  const handleMoveTagItem = (dragIndex: number, hoverIndex: number) => {
    const newPayload = swapArrayItems(payload, dragIndex, hoverIndex);
    saveChanges(newPayload);
  };

  useEffect(() => {
    if (schemas) {
      setParsedSchemas(parseSchemas(schemas));
    }
  }, [schemas]);

  return (
    <>
      <span style={{ fontSize: '45px' }}>{title}</span>
      <div
        className={s.libraryTransformer}
        style={{ paddingTop: '20px', overflow: 'auto', height: '800px' }}
      >
        {payload && (
          <DndList
            items={payload}
            titlePath="transformer"
            uniqueFieldPath="id"
            tagHeaderRight={<Icon icon="trash" />}
            handleTagHeaderRightItemClick={handleDeleteClick}
            renderCollapsedItem={renderCollapsedItem}
            moveTagItem={handleMoveTagItem}
          />
        )}

        {newTransformer && (
          <LibraryNewTransformer
            payload={newTransformer}
            defaultSelect={DEFAULT_TRANSFORMER}
            schemas={parseSchemas(allAvailableTransformersSchemas)}
            cancelCreation={() => setNewTransformer(undefined)}
            handleNewItemChange={(item) => setNewTransformer(item)}
            handleSaveClick={handleSaveClick}
          />
        )}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            icon={<Icon icon={IconNames.ADD} iconSize={IconSize.LARGE} />}
            minimal
            onClick={() =>
              setNewTransformer({
                transformer: '',
                options: {},
                id: uuid(),
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default LibraryRadioTransformers;
