import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { find, has, get } from 'lodash/fp';
import { AnchorButton, Icon } from '@blueprintjs/core';
// import { Tooltip } from 'ui/Tooltip';
import { toFormattedDateTimeUTC } from 'utils/common/dateUtils';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { HISTORY_STATUS_COLOR_MAPPING } from 'constants/rtiCommands/constants';
import { STATUS_ERROR } from 'constants/ops/rti/oparate/constants';
import s from './index.module.scss';
import useUser from 'services/api/portal/administration/hook/useUser';
import type { IUser } from 'services/api/portal/administration/api/types';
import type { CommandDefinition } from 'api/telecommands/types';
import { Tooltip } from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { isReplyOudated } from 'utils/replyUtilities';

interface IProps {
  availableCommands: CommandDefinition[];
  history: ICommandHistory;
  currentHistoryItemId: number | string;
  handleCommandCopyToEditorClick: (
    command: string,
    payload: CommandHistoryPayload,
    commandCustomName: string | undefined
  ) => void;
  handleHistoryCommandClick: (reply: ICommandHistory) => void;
  isTablet?: boolean;
  style?: React.CSSProperties;
  itemMeasureRef: React.LegacyRef<HTMLDivElement>;
}

const ERROR_PATHS = [
  'data.postMessage.errorMessage',
  'data.error.message',
  'data.reason',
  'data.error',
  'responsesContent[0].data.error.message',
];

const printError = (reply: unknown) => {
  const specificPath = find((path) => has(path, reply), ERROR_PATHS);
  const message = get(specificPath ?? 'data', reply) as string;
  return JSON.stringify(message || reply);
};

const CommandHistoryItem = ({
  history,
  availableCommands,
  currentHistoryItemId,
  handleHistoryCommandClick,
  handleCommandCopyToEditorClick,
  style,
  itemMeasureRef,
}: IProps) => {
  const { getUsersById } = useUser();

  // User who sent the cmd
  const [user, setUser] = useState<
    (Omit<IUser, 'picture'> & { picture: string | JSX.Element }) | undefined
  >();

  const { mission } = useParams<{ mission: string }>();

  const currentCommand = find(['name', history.command], availableCommands);

  const getUserWhoSentCmd = useCallback(async () => {
    if (!history.subjectID) {
      return;
    }

    if (history.subjectID?.toLowerCase().includes('@clients')) {
      return setUser({
        email: '',
        email_verified: true,
        id: history.subjectID,
        name: 'API Key',
        picture: <Icon icon="key" />,
      });
    }

    const u = await getUsersById(history.subjectID);
    setUser(u);
  }, [getUsersById, history.subjectID]);

  const renderErrorMessage = (commandHistory: ICommandHistory) => {
    const message = find(
      ['type', STATUS_ERROR],
      commandHistory.responsesContent
    );

    if (!message) return undefined;

    return (
      <div className={s.commandHistoryError}>
        <em>Error:</em> {printError(message)}
      </div>
    );
  };

  const commandHistoryItemContainerStyle =
    currentHistoryItemId === history.sequenceId
      ? [s.commandHistoryItem, s.commandHistoryItem__selected].join(' ')
      : s.commandHistoryItem;

  let iconColor = HISTORY_STATUS_COLOR_MAPPING.error;
  const colorKey = history.status as keyof typeof HISTORY_STATUS_COLOR_MAPPING;
  if (HISTORY_STATUS_COLOR_MAPPING[colorKey]) {
    iconColor = HISTORY_STATUS_COLOR_MAPPING[colorKey];
  }

  useEffect(() => {
    void getUserWhoSentCmd();
  }, [getUserWhoSentCmd]);

  const { hasPermission: isAllowedToOpenCommand } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: currentCommand?.scope,
      id: mission,
    },
    enabled: currentCommand !== undefined,
  });

  return (
    <div
      role="presentation"
      data-testid="command-history-item"
      className={commandHistoryItemContainerStyle}
      onClick={() => handleHistoryCommandClick({ ...history })}
      style={style}
      ref={itemMeasureRef}
    >
      <div className={s.commandHistoryDetails}>
        {history.createdAt ? (
          <div
            className={s.commandHistoryDate}
            data-testid="command-history-item-date"
          >
            {toFormattedDateTimeUTC(history.createdAt)}
          </div>
        ) : null}
        <div className={s.commandHistoryReplies}>
          <div>
            <Icon icon="chat" className={s.repliesHistory} />
            <span
              //@ts-expect-error
              name="repliesCount"
              data-testid="command-history-item-replies-count"
            >
              {history.responsesContent.length}
            </span>
          </div>
        </div>
        <div className="flex items-center">
          {isReplyOudated(history.responsesContent) && (
            <Tooltip content="Mismatch in ground and satellite clock times">
              <Icon icon="warning-sign" />
            </Tooltip>
          )}
        </div>
        <div className={s.commandHistoryStatus}>
          <Icon
            iconSize={20}
            icon="symbol-circle"
            color={iconColor}
            data-testid="command-history-item-status-icon"
          />
        </div>
        <div
          className={s.commandHistoryName}
          data-testid="command-history-item-name"
        >
          <span>
            {history.customCommandName ? `${history.customCommandName} - ` : ''}
          </span>
          <span
            //@ts-expect-error
            name="commandName"
            className={s.commandHistoryNameText}
          >
            {history.command}
          </span>
          <span>
            {history.procedureName ? ` (${history.procedureName})` : ''}
          </span>
        </div>
        {history.note && history.note !== '' ? (
          <div className={s.commandHistoryNotes}>
            <Tooltip content={history.note}>
              <div>
                <Icon icon="comment" />
              </div>
            </Tooltip>
          </div>
        ) : null}

        <Tooltip
          content={
            user?.name ? `Sent by ${user?.name}` : 'Sent by unknown user'
          }
          placement="left"
        >
          {typeof user?.picture === 'string' ? (
            <img
              data-testid="command-history-item-sender-image"
              src={user?.picture}
              className="h-8 w-8 block rounded-full mr-2"
            />
          ) : (
            <div
              data-testid="command-history-item-sender-image"
              className="flex h-8 w-8 rounded-full mr-2 items-center justify-center bg-neutral-400"
            >
              {user?.picture}
            </div>
          )}
        </Tooltip>

        <div className={s.commandHistoryActionButton}>
          <Tooltip
            content="Copy to editor"
            placement="left"
            isDisabled={!isAllowedToOpenCommand}
          >
            <AnchorButton
              className={isAllowedToOpenCommand ? s.activeButton : undefined}
              icon="send-to"
              disabled={!isAllowedToOpenCommand}
              data-testid="command-history-item-copy-to-editor"
              onClick={(event) => {
                if (!history.payload) {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                handleCommandCopyToEditorClick(
                  history.command,
                  history.payload,
                  history.customCommandName
                );
              }}
            />
          </Tooltip>
        </div>
      </div>
      {renderErrorMessage(history)}
    </div>
  );
};

export default CommandHistoryItem;
