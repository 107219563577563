export type SchedulingLayoutProps = {
  left: JSX.Element;
  right: JSX.Element;
  hideRight?: boolean;
  hideLeft?: boolean;
};

export const SchedulingLayout = ({
  left,
  right,
  hideLeft,
  hideRight,
}: SchedulingLayoutProps) => {
  if (hideLeft) {
    return (
      <div
        style={{
          backgroundColor: 'rgb(230, 225, 220)',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
          padding: '0 0 20px 50px',
          overflow: 'auto',
        }}
        className="w-full"
      >
        <div
          style={{
            height: 'calc(100vh - 70px)',
          }}
          className="bg-white w-full"
        >
          {right}
        </div>
      </div>
    );
  }

  if (hideRight) {
    return (
      <div
        style={{
          backgroundColor: 'rgb(230, 225, 220)',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
          padding: '0 0 20px 50px',
          overflow: 'auto',
        }}
        className="w-full"
      >
        <div
          style={{
            height: 'calc(100vh - 70px)',
          }}
          className="bg-white w-full"
        >
          {left}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(230, 225, 220)',
        width: '100%',
        height: 'calc(100vh - 50px)',
        maxHeight: 'calc(100vh - 50px)',
        display: 'grid',
        gridTemplateColumns: '3fr 3fr',
        padding: '0 0 20px 50px',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
      >
        {left}
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
      >
        {right}
      </div>
    </div>
  );
};
