import React, { useEffect, useState } from 'react';
import { useMission } from 'services/Missions';
import NodeRED from './NodeRed';
import NodeREDDashboard from './NodeRedDashboard';
import NoNodeRedInstance from './NoNodeRedInstance';
import {
  Elevation,
  Card,
  Spinner,
  Alert,
  Intent,
  Dialog,
  DialogBody,
  Button,
  MenuItem,
} from '@blueprintjs/core';
import s from './index.module.scss';
import {
  DEFAULT_TAB_STATE,
  SCRIPTING_BOTTOM_NAVBAR_TABS,
} from '../../../../../constants/ops/node-red/constants';
import { useHistory } from 'react-router';
import { useQuery } from 'api/useQuery';
import {
  getScriptingInstanceVersion,
  putScriptingInstanceVersion,
} from 'api/nodeRed/service';
import { Select2 } from '@blueprintjs/select';
import useActiveSession from 'services/api/portal/administration/hook/useActiveSession';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import BottomNavBar from 'pages/ops/Library/components/BottomNavBar';

interface IProps {
  isLoading: boolean;
  isCreating: boolean;
  instanceExists: boolean;
  createNewInstance?: () => void;
}
const TABS = {
  nodered: <NodeRED />,
  dashboard: <NodeREDDashboard />,
};

const ScriptingContainer = (props: IProps) => {
  const { currentMissionId } = useMission();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>();
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);

  const { isSessionOccupied, userIsActiveInCurrentMission } = useActiveSession(
    currentMissionId ?? ''
  );
  const [selectedTab, setSelectedTab] =
    useState<keyof typeof TABS>(DEFAULT_TAB_STATE);

  const [isOpen, setIsOpen] = useState<boolean>(
    isSessionOccupied && !userIsActiveInCurrentMission
  );
  const history = useHistory();

  const getVersionQuery = useQuery(getScriptingInstanceVersion, {
    initialData: {
      available_versions: [],
      current_version: '',
    },
    skip: currentMissionId === undefined,
    params: {
      missionId: currentMissionId!,
    },
  });

  /**
   * TODO: This should not reopen if you've claimed the session. Take a look at it.
   */
  useEffect(() => {
    setIsOpen(isSessionOccupied && !userIsActiveInCurrentMission);
  }, [isSessionOccupied, userIsActiveInCurrentMission]);

  const { hasPermission: isAllowedToManageNodeRED } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'ops:node-red',
      id: currentMissionId,
    },
    enabled: currentMissionId !== undefined,
  });

  const renderAlert = () =>
    !userIsActiveInCurrentMission && (
      <Alert
        cancelButtonText={'Cancel'}
        confirmButtonText={'Confirm'}
        icon="issue"
        isOpen={isOpen}
        intent={Intent.PRIMARY}
        onCancel={() => {
          history.replace('/msd');
        }}
        onConfirm={() => {
          setIsOpen(false);
        }}
      >
        <p>
          The scripting interface is currently in use. Are you sure you want to
          proceed?
        </p>
      </Alert>
    );

  const renderUpdateDialog = () => {
    const availableVersions = getVersionQuery.data.available_versions;
    const currentVersion = getVersionQuery.data.current_version;

    if (currentVersion === '') {
      return;
    }

    const isUpToDate =
      Number(currentVersion?.replace(/\./g, '')) ===
      // Assuming that the last element in the array is the latest version
      Number(
        availableVersions[availableVersions.length - 1]?.replace(/\./g, '')
      );

    if (isUpToDate) {
      return;
    }

    return (
      <Dialog
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
      >
        <DialogBody className="bg-surface border-2 border-item-dark relative">
          <button
            aria-label="Close"
            onClick={() => setIsUpdateModalOpen(false)}
            className="bp5-dialog-close-button bp5-button bp5-minimal bp5-icon-cross absolute right-2 top-1"
          >
            x
          </button>
          <div className="flex flex-col gap-8 p-2">
            <div className="text-center bg-item drop-shadow-md">
              An upgrade for this scripting instance is available
            </div>

            <div className="flex items-center justify-between w-80 self-center drop-shadow-md">
              <div className="flex flex-col items-center bg-item p-1 w-32">
                <span>Current version</span>
                <span>v{getVersionQuery.data.current_version}</span>
              </div>
              <div className="flex flex-col items-center bg-item p-1 drop-shadow-md w-32">
                <span>Upgrade to </span>
                <Select2
                  fill
                  popoverProps={{
                    minimal: true,
                  }}
                  filterable={false}
                  onItemSelect={(item) => {
                    setSelectedVersion(item);
                  }}
                  itemRenderer={(item, { handleClick }) => {
                    return <MenuItem text={item} onClick={handleClick} />;
                  }}
                  items={getVersionQuery.data.available_versions}
                >
                  <Button fill text={selectedVersion ?? 'Select'} />
                </Select2>
              </div>
            </div>

            <Button
              text="Upgrade"
              intent="primary"
              className="drop-shadow-md w-32 self-center"
              loading={isUpgrading}
              onClick={async () => {
                if (!currentMissionId || !selectedVersion) {
                  return;
                }

                setIsUpgrading(true);

                await putScriptingInstanceVersion({
                  params: {
                    missionId: currentMissionId,
                  },
                  body: {
                    version: selectedVersion,
                  },
                });

                setIsUpgrading(false);

                setIsUpdateModalOpen(false);
              }}
            />
          </div>
        </DialogBody>
      </Dialog>
    );
  };

  useEffect(() => {
    const availableVersions = getVersionQuery.data.available_versions;
    const currentVersion = getVersionQuery.data.current_version;

    const isOutdated =
      Number(currentVersion?.replace(/\./g, '')) <
      // Assuming that the last element in the array is the latest version
      Number(
        availableVersions[availableVersions.length - 1]?.replace(/\./g, '')
      );

    const isNan = isNaN(Number(currentVersion.replace(/\./g, '')));

    if (!isSessionOccupied) {
      setIsUpdateModalOpen(isOutdated || isNan);
    }
  }, [getVersionQuery.data, isSessionOccupied]);

  if (props.isLoading && !props.isCreating) {
    return (
      <div className={s.center}>
        <Spinner />
      </div>
    );
  }

  if (props.instanceExists) {
    return (
      <>
        {TABS[selectedTab] || null}
        <BottomNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab as (value: string) => void}
          tabList={SCRIPTING_BOTTOM_NAVBAR_TABS}
        />
        {renderAlert()}
        {renderUpdateDialog()}
      </>
    );
  }

  if (isAllowedToManageNodeRED) {
    return (
      <>
        <NoNodeRedInstance
          createNewInstance={props.createNewInstance}
          isCreating={props.isCreating}
        />
        {renderAlert()}
        {renderUpdateDialog()}
      </>
    );
  }

  return (
    <div className={s.libraryCardContainer}>
      <Card interactive={true} elevation={Elevation.TWO}>
        <span>
          No scripting instance currently running. <br /> Please contact
          administrator to create a new instance
        </span>
      </Card>
    </div>
  );
};

export default ScriptingContainer;
