import React, { useCallback, useEffect, useState } from 'react';
import { get, pipe, invert } from 'lodash/fp';
import type { IBreadcrumbProps } from '@blueprintjs/core';
import {
  Breadcrumbs,
  ContextMenu,
  Menu,
  MenuItem,
  Alert,
  Intent,
  Classes,
  InputGroup,
  Button,
  NonIdealState,
} from '@blueprintjs/core';
import { isNil } from 'lodash';

import type { IFolder } from '../../../../../../services/api/ops/cloudStorage';
import FileDropzone from '../../../../../../components/Dropzone/FileDropzone';
import { fileExplorerSorter } from '../../utils/fileExplorerSorter';
import {
  EntryType,
  SORTED_LIST_MAPPING,
} from '../../../../../../constants/fileExplorer/constants';
import useFolderDnd from '../../hooks/fileExplorer/useFolderDnd';
import InputFileButton from '../../../../../../components/buttons/InputFileButton';
import { removeExcessSlash } from '../../../../../../utils/common/stringUtils';
import { useFileExplorer } from 'pages/ops/RTI/Operate/context/FileExplorerProvider';

import type { IFolderTreeItem } from './FolderTree';
import FolderTree from './FolderTree';
import PreviewDialog from './PreviewDialog';
import PropertiesDialog from './PropertiesDialog';

import s from './index.module.scss';
import { IconNames } from '@blueprintjs/icons';
import type { CloudStorageContent } from 'api/cloudStorage/types';

export interface IFileExplorer {
  errorMessage?: string;
  rootItem?: string;
  currentPath?: string;
  acceptedDropType?: string;
  disableDropzone?: boolean;
  simplifiedExplorer?: boolean;
  fileContent?: IFolder;
  folderContent: CloudStorageContent[];
  handleDeleteContent: (fileName: string, filePath?: string) => void;
  handleReloadClick: () => void;
}

interface IDeleteItemState {
  isAlertOpen: boolean;
  alertText: string;
  name: string;
  path?: string;
}

const DEFAULT_DELETE_ITEM_STATE = {
  isAlertOpen: false,
  alertText: '',
  name: '',
  path: '',
};

export type OrderByType = 'asc' | 'desc';

const FileExplorer = ({ handleDeleteContent, ...props }: IFileExplorer) => {
  const { isOver, isOverCurrent, drop } = useFolderDnd(
    props.acceptedDropType,
    props.currentPath
  );

  const {
    setUploadStatus,
    setCalculatedChecksum,
    handleDirectoryPathCopy,
    handleBreadcrumbsClick,
    handleCopyFilePath,
    isPreviewOpen,
    handlePreviewDialogClose,
    handleCalculateCRCClick,
    calculatedChecksum,
    showPreviewWarnWindow,
    handlePreviewWarnConfirm,
    setPreviewWarnWindow,
    createBreadCrumbs,
    toBreadcrumbsTreeItems,
    canCreateFolder,
    canUploadFile,
  } = useFileExplorer();

  const [sortBy, setSortBy] = useState('name');
  const [orderBy, setOrderBy] = useState<OrderByType>('asc');
  const [showCreateFolderWindow, setShowCreateFolderWindow] =
    useState<boolean>(false);
  const [isPropertiesOpen, setPropertiesOpen] = useState(false);
  const [propertiesContent, setPropertiesContent] = useState<IFolderTreeItem>();
  const [deleteItemState, setDeleteItemState] = useState<IDeleteItemState>({
    ...DEFAULT_DELETE_ITEM_STATE,
  });

  const closePreviewWarnWindow = useCallback(() => {
    setPreviewWarnWindow(false);
  }, [setPreviewWarnWindow]);
  const breadcrumbsItems = createBreadCrumbs();
  const folderTreeItems = toBreadcrumbsTreeItems(props.folderContent);
  const [isShowInputFolderPath, setIsShowInputFolderPath] =
    useState<boolean>(false);
  const [currentPathValue, setCurrentPathValue] = useState<string>();

  const handleDeleteItem = useCallback(
    () => handleDeleteContent(deleteItemState.name, deleteItemState.path),
    [deleteItemState.name, deleteItemState.path, handleDeleteContent]
  );

  const handleSortByChange = (headerName: string) => {
    const newSortBy = pipe(invert, get(headerName))(SORTED_LIST_MAPPING);

    if (!newSortBy) return;

    if (newSortBy === sortBy) {
      setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
      return;
    }

    setSortBy(newSortBy);
  };

  const handleDeleteItemClick = (fileName: string, path: string) => {
    setDeleteItemState({
      isAlertOpen: true,
      alertText: `Do you want to delete '${fileName}' ?`,
      name: fileName,
      path: path,
    });
  };

  const handleDeleteCloseClick = () => {
    setDeleteItemState({ ...DEFAULT_DELETE_ITEM_STATE });
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setUploadStatus(e.target.files);
    ContextMenu.hide();
  };

  const handlePropertiesDialogClose = () => {
    setPropertiesContent(undefined);
    setPropertiesOpen(false);
    setCalculatedChecksum(undefined);
  };

  const handlePropertiesDialogOpen = (node: IFolderTreeItem) => {
    setPropertiesContent(node);
    setPropertiesOpen(true);
  };

  const showContextMenu = (e: React.MouseEvent): void => {
    e.preventDefault();
    ContextMenu.show(
      <Menu>
        <MenuItem onClick={props.handleReloadClick} text="Reload" />
        {canUploadFile && (
          <InputFileButton
            multiple
            id="cloud-storage-file-upload"
            className={Classes.MENU_ITEM}
            text="Upload File"
            onChange={handleUploadFile}
          />
        )}
        {canCreateFolder && (
          <MenuItem
            onClick={() => setShowCreateFolderWindow(true)}
            text="Create folder"
          />
        )}
      </Menu>,
      { left: e.clientX, top: e.clientY }
    );
  };

  const showBreadcrumbContextMenu = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    handleDirectoryPathCopy();
  };

  const createBreadcrumbItems = () => {
    const rootPath = !isNil(props.rootItem) ? props.rootItem : '/';
    const breadcrumbs: IBreadcrumbProps[] = [
      {
        text: props.rootItem ?? 'root',
        onClick: () => {
          setIsShowInputFolderPath(true);
          handleBreadcrumbsClick(rootPath);
        },
      },
    ];
    let accumulator = '/';

    breadcrumbsItems.map((item, i) => {
      accumulator += removeExcessSlash(item) + '/';
      const crumbPath = accumulator;
      breadcrumbs.push({
        text: <span onContextMenu={showBreadcrumbContextMenu}>{item}</span>,
        onClick: () => {
          if (breadcrumbsItems.length - 1 === i) {
            setIsShowInputFolderPath(true);
          } else {
            handleBreadcrumbsClick(crumbPath);
          }
        },
      });
    });

    return breadcrumbs;
  };

  useEffect(() => {
    const path = props.currentPath
      ?.split('/')
      .filter((str) => str !== '')
      .join('/');

    path && path.length > 0
      ? setCurrentPathValue('/' + path)
      : setCurrentPathValue(props.currentPath);
  }, [props.currentPath, props.folderContent]);

  const onBreadcrumbPathInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setCurrentPathValue(inputValue);
  };

  const onFilePathChanged = () => {
    let path = currentPathValue;
    // Folders must end with / in cloud storage. Because only the contents of
    // folders can be visualised, assume the user always intends to input a
    // folder path.
    if (!path?.endsWith('/')) {
      path += '/';
    }
    handleBreadcrumbsClick(path);
    setIsShowInputFolderPath(false);
  };

  let contents = (
    <FolderTree
      items={folderTreeItems}
      handleDeleteItemClick={handleDeleteContent && handleDeleteItemClick}
      handlePropertiesDialogOpen={handlePropertiesDialogOpen}
      handleCopyFilePath={handleCopyFilePath}
      handleUploadClick={setUploadStatus}
      showCreateFolderWindow={showCreateFolderWindow}
      headerNames={
        props.simplifiedExplorer ? ['Name'] : Object.values(SORTED_LIST_MAPPING)
      }
      sortedBy={get(sortBy, SORTED_LIST_MAPPING)}
      handleSortByChange={handleSortByChange}
      orderBy={orderBy}
      sorter={fileExplorerSorter(sortBy, EntryType.FOLDER, orderBy)}
      simplifiedExplorer={props.simplifiedExplorer}
    />
  );

  if (props.errorMessage) {
    contents = (
      <NonIdealState icon={IconNames.WARNING_SIGN} title={props.errorMessage} />
    );
  }

  return (
    <FileDropzone
      disabled={props.disableDropzone ?? !props.currentPath}
      handleDropFile={setUploadStatus}
    >
      <div
        ref={props.currentPath ? drop : null}
        className={
          isOver && isOverCurrent
            ? [s.fileExplorerWrapper, s.isDropable].join(' ')
            : s.fileExplorerWrapper
        }
        onContextMenu={props.currentPath ? showContextMenu : null}
      >
        {isShowInputFolderPath ? (
          <InputGroup
            asyncControl={true}
            leftIcon={IconNames.FOLDER_OPEN}
            rightElement={<Button onClick={onFilePathChanged}>Go</Button>}
            value={currentPathValue}
            onChange={(e) => onBreadcrumbPathInput(e)}
            onKeyDown={(e) => e.key === 'Enter' && onFilePathChanged()}
          />
        ) : (
          <Breadcrumbs
            className={s.breadcrumb}
            minVisibleItems={1}
            items={createBreadcrumbItems()}
          />
        )}

        {contents}
      </div>
      <PreviewDialog
        handleUploadClick={setUploadStatus}
        isPreviewOpen={isPreviewOpen}
        fileContent={props.fileContent}
        handlePreviewDialogClose={handlePreviewDialogClose}
      />
      <PropertiesDialog
        isPropertiesOpen={isPropertiesOpen}
        handlePropertiesDialogClose={handlePropertiesDialogClose}
        handleCalculateCRCClick={handleCalculateCRCClick}
        content={propertiesContent}
        calculatedChecksum={calculatedChecksum}
      />
      <Alert
        icon="trash"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        canOutsideClickCancel={true}
        intent={Intent.DANGER}
        isOpen={deleteItemState.isAlertOpen}
        onClose={handleDeleteCloseClick}
        onConfirm={handleDeleteItem}
      >
        {deleteItemState.alertText}
      </Alert>
      <Alert
        icon="issue"
        confirmButtonText="Open"
        cancelButtonText="Cancel"
        canOutsideClickCancel={true}
        isOpen={showPreviewWarnWindow}
        onClose={() => closePreviewWarnWindow()}
        onConfirm={handlePreviewWarnConfirm}
      >
        <p>Unable to determine file type.</p>
        <p>Do you want to open it?</p>
      </Alert>
    </FileDropzone>
  );
};

export default FileExplorer;
