import React, { useState } from 'react';
import { ControlGroup, InputGroup, Button, Intent } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import { useMission } from 'services/Missions';
import { useApiPostOpsNewTerminalSessions } from 'services/api/ops/terminalSessions';

interface IProps {
  handleConnectToSession: (sessionId: number) => void;
  onSessionCreated?: () => any;
  writeAllSessionsPermission: boolean;
}

const NewTerminalSession = (props: IProps) => {
  const [sessionName, setSessionName] = useState('');
  const [userAccessible, setUserAccessible] = useState(true);
  const { currentMissionId } = useMission();
  const postApiOpsNewTerminalSessions = useApiPostOpsNewTerminalSessions();
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);
    const payload = {
      name: sessionName,
      missionId: currentMissionId?.toString() ?? '',
      userAccessible: userAccessible,
    };

    const res = await postApiOpsNewTerminalSessions(payload);

    props.onSessionCreated?.();
    setLoading(false);
    setSessionName('');

    return res;
  };

  const handleSubmit = async () => {
    const res = await handleCreate();

    if (res) {
      props.handleConnectToSession(res.id);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value as string;

    setSessionName(value);
  };

  const handleVisibilityChange = () => {
    setUserAccessible(!userAccessible);
  };

  return (
    <ControlGroup>
      <InputGroup
        required
        fill
        placeholder="Session name..."
        value={sessionName}
        onChange={handleInputChange}
      />
      {props.writeAllSessionsPermission && (
        <Tooltip content="Control customer accessibility of the new session">
          <Button
            icon={userAccessible ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
            onClick={handleVisibilityChange}
          />
        </Tooltip>
      )}
      <Button
        icon={IconNames.ADD_TO_ARTIFACT}
        text="Create"
        onClick={handleCreate}
        loading={loading}
      />
      <Button
        icon={IconNames.OFFLINE}
        intent={Intent.PRIMARY}
        text="Create and connect"
        onClick={handleSubmit}
        loading={loading}
      />
    </ControlGroup>
  );
};

export default NewTerminalSession;
