import React from 'react';
import { startCase } from 'lodash/fp';
import { Button, ControlGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import TagHeader from '../../../../../../components/common/TagHeader';

import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

interface IProps {
  handleHistoryTypeChange: (type: string) => void;
  historyCommandType?: string;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const CommandHistoryHeader = (props: IProps) => {
  const { sendInfo } = useAnalytics();
  return (
    <TagHeader
      className={s.historyTagContainer}
      title="Command History"
      icon="history"
      intent={Intent.PRIMARY}
      autoAlignTitle={false}
    >
      <span className={s.commandHistoryHeaderButtons}>
        <ControlGroup>
          {!props.collapsed && (
            <HTMLSelect
              minimal
              value={startCase(props.historyCommandType ?? 'Session')}
              onChange={(e) => {
                sendInfo({
                  type: 'Command history type change',
                  action: 'Change',
                  item: 'Command history type',
                  module: 'OPS',
                  additionalParams: {
                    type: e.target.value.toLowerCase(),
                  },
                });
                props.handleHistoryTypeChange(e.target.value.toLowerCase());
              }}
            >
              <option>Session</option>
              <option>Mission</option>
            </HTMLSelect>
          )}
          <Button
            icon={
              props.collapsed ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN
            }
            minimal
            small
            onClick={() => {
              sendInfo({
                type: 'Command history collapse',
                action: 'Click',
                item: 'Command history collapse button',
                module: 'OPS',
              });
              props.setCollapsed(!props.collapsed);
            }}
          />
        </ControlGroup>
      </span>
    </TagHeader>
  );
};

export default CommandHistoryHeader;
