import { toaster } from 'toaster';
import {
  BandAlgebraInputType,
  COLORMAP,
} from 'datacosmos/entities/bandAlgebraLayer';
import type { ICustomBandAlgebra } from 'datacosmos/services/localStorageBandAlgebra';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { COMMON_BAND_NAMES } from 'datacosmos/utils/algebraExpressionFormatter';
import { Formik } from 'formik';
import { v1 as uuid } from 'uuid';
import Button from '_molecules/Button/Button';
import Input from '_molecules/Input/Input';
import SuggestionInput from '_molecules/SuggestionInput/SuggestionInput';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  saveCustomAlgebra: (algebra: ICustomBandAlgebra) => void;
  toggleSelectedIndex: (index: IExpressionDetails) => void;
}

const MultiChannel = (props: IProps) => {
  const { translate } = useLocalisation();

  const generateErrors = (values: Object, excludedFields?: string[]) => {
    return Object.entries(values)
      .filter(
        ([key, val]) =>
          (val === '' || val === undefined) && !excludedFields?.includes(key)
      )
      .map(([key]) => ({
        [key]: translate(
          `datacosmos.layers.bandAlgebra.custom.rgb.errors.${key}` as unknown as TemplateStringsArray
        ),
      }))
      .reduce((obj, item) => {
        obj = { ...obj, [Object.keys(item)[0]]: Object.values(item)[0] };
        return obj;
      }, {});
  };
  return (
    <Formik
      initialValues={{
        r: '',
        g: '',
        b: '',
        min: undefined as string | undefined,
        max: undefined as string | undefined,
        name: undefined as string | undefined,
        description: undefined as string | undefined,
        apply: false,
      }}
      onSubmit={(values, { setErrors, setValues }) => {
        const errors = generateErrors(values, ['description', 'apply']);
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        }
        props.saveCustomAlgebra({
          id: uuid(),
          colorMap: COLORMAP.ACCENT,
          expression: `${values.r},${values.g},${values.b}`,
          max: parseFloat(values.max ? values.max : '0'),
          min: parseFloat(values.min ? values.min : '0'),
          uiName: values.name ? values.name : '',
          description: values.description,
          algebraType: BandAlgebraInputType.MULTIBAND,
        });

        if (values.apply) {
          props.toggleSelectedIndex({
            expression: `${values.r},${values.g},${values.b}`,
            recommendedColormap: COLORMAP.ACCENT,
            max: parseFloat(values.max ? values.max : '0'),
            min: parseFloat(values.min ? values.min : '0'),
            uiName: values.name ? values.name : '',
            description: values.description,
            algebraType: BandAlgebraInputType.MULTIBAND,
          });
          setValues({ ...values, apply: false });
        }

        toaster.show({
          message: translate('datacosmos.layers.bandAlgebra.custom.success', {
            name: values.name,
          }),
          intent: 'success',
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        setValues,
        errors,
        setFieldValue,
      }) => {
        return (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <SuggestionInput
                  type="text"
                  label={{ text: 'R', position: 'left' }}
                  name="r"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={translate(
                    'datacosmos.catalogAndItems.metadata.bandColors.red'
                  )}
                  errorMsg={errors.r?.toString()}
                  value={values.r}
                  suggestionItems={COMMON_BAND_NAMES}
                  onSuggestionClick={(suggestion) => {
                    setFieldValue('r', suggestion);
                  }}
                  popoverProps={{ target: 'right-menu' }}
                />
                <SuggestionInput
                  type="text"
                  label={{ text: 'G', position: 'left' }}
                  name="g"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={translate(
                    'datacosmos.catalogAndItems.metadata.bandColors.green'
                  )}
                  errorMsg={errors.g?.toString()}
                  value={values.g}
                  suggestionItems={COMMON_BAND_NAMES}
                  onSuggestionClick={(suggestion) => {
                    setFieldValue('g', suggestion);
                  }}
                  popoverProps={{ target: 'right-menu' }}
                />
                <SuggestionInput
                  type="text"
                  label={{ text: 'B', position: 'left' }}
                  name="b"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={translate(
                    'datacosmos.catalogAndItems.metadata.bandColors.blue'
                  )}
                  errorMsg={errors.b?.toString()}
                  value={values.b}
                  suggestionItems={COMMON_BAND_NAMES}
                  onSuggestionClick={(suggestion) => {
                    setFieldValue('b', suggestion);
                  }}
                  popoverProps={{ target: 'right-menu' }}
                />
              </div>
            </div>

            {/* Add min max boxes here */}

            <div className="flex items-center justify-between">
              <Input
                type="number"
                label={{
                  text: translate(
                    'datacosmos.layers.bandAlgebra.custom.rgb.minimmum'
                  ),
                  position: 'left',
                }}
                onChange={handleChange}
                name="min"
                className="w-16"
                errorMsg={errors.min?.toString()}
              />
              <span>-</span>
              <Input
                type="number"
                label={{
                  text: translate(
                    'datacosmos.layers.bandAlgebra.custom.rgb.maximum'
                  ),
                  position: 'right',
                }}
                onChange={handleChange}
                name="max"
                className="w-16"
                errorMsg={errors.max?.toString()}
              />
            </div>

            <div className="flex flex-col gap-8">
              <Input
                label={{
                  text: translate('datacosmos.layers.bandAlgebra.custom.name'),
                  position: 'left',
                }}
                type="text"
                name="name"
                onChange={handleChange}
                errorMsg={errors.name?.toString()}
              />

              <Input
                label={{
                  text: translate(
                    'datacosmos.layers.bandAlgebra.custom.description'
                  ),
                  position: 'left',
                }}
                type="textarea"
                name="description"
                onChange={handleChange}
              />
            </div>

            <div className="flex w-full justify-end gap-4">
              <Button
                text={translate('datacosmos.layers.bandAlgebra.custom.save')}
                icon="Save"
                iconPlacement="left"
                onPress={() => handleSubmit()}
              />

              <Button
                text={translate(
                  'datacosmos.layers.bandAlgebra.custom.saveAndApply'
                )}
                icon="Save"
                iconPlacement="left"
                onPress={() => {
                  setValues({ ...values, apply: true });
                  handleSubmit();
                }}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default MultiChannel;
