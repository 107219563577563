import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { CloudStatusList } from 'api/activities/service';
import type { CloudStatus, Activity } from 'api/activities/service';
import {
  CompositeDetailsItem,
  DetailsItem,
  JSONDetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import { Select2 } from '@blueprintjs/select';
import Textarea from '_atoms/Textarea/Textarea';
import { AlertDialog } from 'opencosmos-ui';
import { useState } from 'react';
import type {
  UpdatedActivityMap,
  UpdatedActivityValue,
} from '../../context/ActivitiesProvider';

type ActivityDetailsCardProps = {
  activity: Activity;
  updatedActivitiesMap: UpdatedActivityMap | undefined;
  updateActivity: (activityId: string, value: UpdatedActivityValue) => void;
  batchSubmitUpdateActivities: () => Promise<void>;
};

const ACTIVITY_STATUSES: Activity['status'][] = [
  'PENDING',
  'APPROVED',
  'CONFIRMED',
  'SCHEDULED',
  'PROCESSING',
  'COMPLETED',
  'EXPIRED',
  'FAILED',
  'CANCELLED',
];

export const ActivityDetailsCard = ({
  activity,
  updatedActivitiesMap,
  updateActivity,
  batchSubmitUpdateActivities,
}: ActivityDetailsCardProps) => {
  const [shouldOpenCancelDialog, setShouldOpenCancelDialog] =
    useState<boolean>(false);
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <h2>Activity</h2>

        <div className="flex items-center gap-2">
          <span>Status: </span>
          <Select2<Activity['status']>
            onItemSelect={(item) => {
              updateActivity(activity.id, { status: item });
            }}
            items={ACTIVITY_STATUSES}
            filterable={false}
            itemRenderer={(item, { handleClick, modifiers }) => (
              <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item}
                text={item}
                onClick={handleClick}
              />
            )}
            className="w-32"
          >
            <Button
              style={{
                minWidth: '8rem',
              }}
              text={
                updatedActivitiesMap?.[activity.id]?.status ?? activity.status
              }
              rightIcon="double-caret-vertical"
            />
          </Select2>
          <Button
            text="Update status"
            className="w-32 h-5"
            intent={Intent.DANGER}
            onClick={() => {
              if (updatedActivitiesMap?.[activity.id].status === 'CANCELLED') {
                setShouldOpenCancelDialog(true);
                return;
              }
              void batchSubmitUpdateActivities();
            }}
            disabled={activity.status === 'CANCELLED'}
          />
        </div>
      </div>

      <div className="flex items-center gap-2 mb-2">
        <div className="flex-1" />
        <label className="text-sm whitespace-nowrap">Cloud status</label>
        <Select2<CloudStatus>
          items={[...CloudStatusList]}
          filterable={false}
          itemRenderer={(item, { handleClick, modifiers }) => (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={item}
              text={item}
              onClick={handleClick}
              selected={
                item ===
                updatedActivitiesMap?.[activity.id]?.parameters?.metrics
                  ?.cloud_status
              }
            />
          )}
          onItemSelect={(status) => {
            updateActivity(activity.id, {
              parameters: {
                metrics: {
                  cloud_status: status as CloudStatus,
                },
              },
            });
          }}
          className="w-32"
        >
          <Button
            text={
              updatedActivitiesMap?.[activity.id]?.parameters?.metrics
                ?.cloud_status ?? activity.parameters.metrics?.cloud_status
            }
            rightIcon="double-caret-vertical"
            className="bp4-fill bp4-select"
            style={{
              textAlign: 'left',
              display: 'block',
            }}
          />
        </Select2>
        <Button
          className="self-end h-8 w-32"
          text="Update"
          onClick={() => {
            void batchSubmitUpdateActivities();
          }}
          style={{
            minWidth: '8rem',
          }}
          intent="primary"
        />
      </div>

      <div className="flex flex-col justify-between gap-3">
        <DetailsItem title="ID: " value={activity.id} />
        <DetailsItem title="Status: " value={activity.status} />
        <DetailsItem title="Mission ID: " value={activity.mission_id} />
        <DetailsItem
          title="Imager ID:"
          value={activity.parameters?.imager?.name ?? ''}
        />

        <DetailsItem
          title="Priority: "
          value={activity?.priority?.toString()}
        />

        <DetailsItem title="Start Time: " value={activity.start_date} />
        <DetailsItem title="End Time: " value={activity.end_date} />
        <DetailsItem
          title="Roll Angle: "
          value={`${Number(activity.parameters.platform?.roll_angle).toFixed(
            2
          )} deg`}
        />

        <DetailsItem
          title="Observation Zenith Angle:"
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.oza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem
          title="Sun Zenith Angle: "
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.sza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem title="Coverage: " value="-" />

        <DetailsItem
          title="STAC Processing Level: "
          value={
            activity.parameters.stac
              ? // Here I am assuming that processing levels will always be listed in order
                // from least to most processed.
                `${
                  activity.parameters.stac[activity.parameters.stac.length - 1]
                    .processing_level
                }(${
                  activity.parameters.stac[activity.parameters.stac.length - 1]
                    .stac_link_time_event_processed
                })`
              : 'N/A'
          }
        />
        {activity.parameters.imager && (
          <JSONDetailsItem title="Imager" value={activity.parameters.imager} />
        )}
        {activity.parameters.platform && (
          <JSONDetailsItem
            title="Platform"
            value={activity.parameters.platform}
          />
        )}
        {activity.parameters.metrics && (
          <JSONDetailsItem
            title="Metrics"
            value={activity.parameters.metrics}
          />
        )}
        {activity.parameters.ground_processing && (
          <JSONDetailsItem
            title="Ground processing"
            value={activity.parameters.ground_processing}
          />
        )}
        {activity.parameters.session && (
          <JSONDetailsItem
            title="Session"
            value={activity.parameters.session}
          />
        )}
        {activity.parameters.onboard_processing && (
          <JSONDetailsItem
            title="Onboard Processing"
            value={activity.parameters.onboard_processing}
          />
        )}
        {activity.parameters.stac && (
          <JSONDetailsItem title="STAC" value={activity.parameters.stac} />
        )}

        <CompositeDetailsItem
          title="Operator notes: "
          element={
            <div>
              <Textarea
                value={
                  updatedActivitiesMap?.[activity.id]?.operator_notes ??
                  activity.operator_notes
                }
                onChange={(e) => {
                  updateActivity(activity.id, {
                    operator_notes: e.currentTarget.value,
                  });
                }}
              />
              <Button
                intent="primary"
                text="Submit"
                onClick={() => {
                  void batchSubmitUpdateActivities();
                }}
              />
            </div>
          }
        />

        <AlertDialog
          actionLabel="Confirm"
          title="Warning"
          onAction={() => {
            void batchSubmitUpdateActivities();
            setShouldOpenCancelDialog(false);
          }}
          onClose={() => {
            setShouldOpenCancelDialog(false);
          }}
          isOpen={shouldOpenCancelDialog}
        >
          <span>Are you sure you want to cancel activity {activity.id}?</span>
        </AlertDialog>
      </div>
    </div>
  );
};
