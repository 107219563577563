import React, { useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Classes,
  ControlGroup,
  Icon,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { isEmpty, isNumber, toNumber } from 'lodash/fp';
import s from '../index.module.scss';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  meta?: string;
  fullPath: string;
  value: any;
  disabled: boolean;
  path?: string;
  showInfoMark?: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  validateChange: Function;
  handleMetaButtonClick: ((value: string) => void) | undefined;
  setDefaultValue: Function;
  setTooltipOpen: (open: boolean) => void;
}

const CommandPrimitiveNumber = (props: IProps) => {
  const {
    inputRef,
    className,
    value,
    onChange,
    fullPath,
    disabled,
    validateChange,
    handleBlur,
    handleFocus,
    path,
    meta,
    showInfoMark,
    handleMetaButtonClick,
    setDefaultValue,
    setTooltipOpen,
  } = props;

  setDefaultValue();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateChange(e);
    const { value: v } = e.target;
    const valueForSave = !isEmpty(v) ? toNumber(v) : v;
    onChange(valueForSave, fullPath);
  };

  const handleArrowNumberInputChange = (value: number) => {
    const fakeEvent: any = {
      target: {
        value,
        id: path,
        name: path,
      },
    };

    validateChange(fakeEvent);
    onChange(value, fullPath);
  };

  const handleMetaClick = useCallback(
    () => handleMetaButtonClick && meta && handleMetaButtonClick(meta),
    [meta]
  );

  const infoIconStyles = meta
    ? [s.iconWithInput, s.iconWithInputExplore].join(' ')
    : [s.iconWithInput, s.iconWithInputNumber].join(' ');

  return (
    <ControlGroup className={Classes.NUMERIC_INPUT} fill>
      <input
        ref={inputRef}
        className={className}
        id={path ?? 'input_number'}
        name={path ?? 'input_number'}
        disabled={disabled}
        onBlur={handleBlur}
        onMouseOver={() => {
          handleFocus();
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
        type="number"
        value={isNumber(value) ? value : ''}
        onChange={handleInputChange}
      />
      {showInfoMark && (
        <Icon className={infoIconStyles} icon={IconNames.INFO_SIGN} />
      )}
      {meta ? (
        <Button disabled={disabled} text="explore" onClick={handleMetaClick} />
      ) : (
        <ButtonGroup
          className={Classes.FIXED}
          key="button-group"
          vertical={true}
        >
          <Button
            icon={IconNames.CHEVRON_UP}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onMouseOut={!disabled ? handleBlur : undefined}
            onMouseOver={!disabled ? handleFocus : undefined}
            onClick={() =>
              handleArrowNumberInputChange(toNumber(value || 0) + 1)
            }
          />
          <Button
            icon={IconNames.CHEVRON_DOWN}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onMouseOut={!disabled ? handleBlur : undefined}
            onMouseOver={!disabled ? handleFocus : undefined}
            onClick={() =>
              handleArrowNumberInputChange(toNumber(value || 0) - 1)
            }
          />
        </ButtonGroup>
      )}
    </ControlGroup>
  );
};

export default CommandPrimitiveNumber;
