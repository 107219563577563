import type { MutableRefObject } from 'react';
import React, {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import config from 'datacosmos/config';
import DSZoom from 'datacosmos/utils/ds_zoom';
import Legend from 'datacosmos/utils/map_legend';
import type { ISTACOptions } from 'datacosmos/entities/singleBandLayer';
import baseLayers from 'datacosmos/utils/map-layers';
import type { IMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IAsset, IStacItem } from 'datacosmos/types/stac-types';
import {
  isAssetCloudOptimised,
  isAssetPreviewable,
  isAssetGeoJson,
  isAssetKML,
} from 'datacosmos/utils/stac';
import type { ILayerOptions, Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import tilingApi from 'datacosmos/services/tilingApi';
import type { IImageCatalogContext } from 'datacosmos/stores/ImageCatalogProvider';
import { useAuth } from 'services/auth/AuthWrapper';
import _ from 'lodash';
import type { IGeoJSONLayerOptions } from 'datacosmos/entities/geojsonLayer';
import {
  GeoJSONLayer,
  GeoJSONLayerFactory,
} from 'datacosmos/entities/geojsonLayer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import showMenu from 'datacosmos/components/RightClickMenu/index';
import type {
  Content,
  LatLng,
  LatLngExpression,
  LeafletMouseEvent,
  Map,
  DrawMap,
  LatLngBoundsExpression,
  LeafletEvent,
} from 'leaflet';
import { LineLayer, LineLayerFactory } from 'datacosmos/entities/lineLayer';
import {
  PolygonLayer,
  PolygonLayerFactory,
} from 'datacosmos/entities/polygonLayer';
import area from '@turf/area';
import circle from '@turf/circle';
import bboxPolygon from '@turf/bbox-polygon';
import booleanWithin from '@turf/boolean-within';
import {
  CircleLayer,
  CircleLayerFactory,
} from 'datacosmos/entities/circleLayer';
import type { IProjectProviderContext } from 'datacosmos/stores/ProjectProvider';
import type { IApplicationCatalogContext } from 'datacosmos/stores/ApplicationCatalogContext';
import { VideoLayer } from 'datacosmos/entities/videoLayer';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import {
  stringToMapView,
  getOCLayers,
  getLayersFromArrayOfIds,
  assignPaneToLayer,
  newTileLayer,
  newLayerFromExpression,
  newVideoLayer,
  newOutlineLayer,
  newGeoJSONLayer,
  newKMLLayer,
  setPopupContent,
  newMapMarkerLayer,
  newPointLayer,
} from 'datacosmos/stores/mapHelpers';
import type { OCLeafletLayer } from 'datacosmos/stores/mapHelpers';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-kml';
import 'datacosmos/utils/SplitMap';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { MapMarkerLayer } from 'datacosmos/entities/mapMarkerLayer';
import { useFilters } from './FiltersProvider';
import polygonSelfIntersecting from 'utils/polygonSelfIntersecting';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { PointLayer } from 'datacosmos/entities/pointLayer';
import { LANGUAGE } from 'env';
import drawLocales from './mapLocales';

if (LANGUAGE === 'es') {
  L.drawLocal.draw = drawLocales.es.draw;
  L.drawLocal.edit = drawLocales.es.edit;
}

export const BASE_Z_INDEX = 200; // To be in line with https://leafletjs.com/reference-1.7.1.html#map-pane

export type IMapContext = ReturnType<typeof useMapHook>;

export const MapContext = createContext<IMapContext>(
  null as unknown as IMapContext
);
export const useMap = () => useContext<IMapContext>(MapContext);

type IHookArgs = {
  catalog: IImageCatalogContext;
  projects: IProjectProviderContext;
  layers: IMapLayers;
  applicationCatalog: IApplicationCatalogContext;
};

export interface IMapBaseLayer {
  id: string;
  name: string;
  url: string;
}
export const useMapHook = ({ layers, applicationCatalog }: IHookArgs) => {
  const { isDarkmode } = useTheme();
  const { token } = useAuth();
  const mapRef = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const comparisonControlRef = useRef(null);

  const mapLayers = useMapLayers();
  const {
    removeLayer,
    addLayer,
    selectLayer,
    isLayersMenuOpen,
    setLayersMenuOpen,
  } = mapLayers;

  const { setApplicationAOIs } = applicationCatalog;

  const [zoomLevel, setZoomLevel] = useState<number | null>(null);
  const [currentCenter, setCurrentCenter] = useState<LatLng | undefined>();
  const [layersComparisonGroups, setLayersComparisonGroups] = useState<
    [string[], string[]] | null
  >(null);

  const isLayersComparisonToggled = layersComparisonGroups !== null;

  const [mapBaseLayers, setMapBaseLayers] = useState(baseLayers);

  const [baseLayer, setBaseLayer] = useState(
    isDarkmode ? baseLayers[1] : baseLayers[0]
  );

  const [scale, setScale] = useState<{
    kmValue: number;
    scalePxWidth: number;
  }>();

  const { sendInfo } = useAnalytics();

  const filtersProvider = useFilters();

  const mapBaseLayer = useRef(
    L.tileLayer(baseLayer.url, { maxZoom: config.map.maxZoom })
  );

  const isDrawing = useRef(false);

  const setZoomAndCenter = useCallback(
    (
      lat: number | null | undefined,
      lng: number | null | undefined,
      zoom: number | null | undefined
    ) => {
      if (!mapRef.current) return;
      if (lat === undefined || lng === undefined || zoom === undefined) return;
      if (lat === null || lng === null || zoom === null) return;
      const center = L.latLng(lat, lng);
      mapRef.current.setView(center, zoom);
    },
    []
  );

  /**
   * drawRectange allows the user to draw a rectangle on the map. When the user
   * completes the shape, the resulting GeoJSON Polygon is resolved by the
   * Promise.
   */
  const drawRectangle = useCallback(
    (
      options: {
        color: string;
        weight: number;
      } = {
        color: 'red',
        weight: 3,
      }
    ) => {
      return new Promise<{
        rectangle: GeoJSON.Feature<GeoJSON.Polygon>;
        rectangleMetadata: unknown;
      }>((resolve) => {
        if (isDrawing.current) {
          return;
        }
        // Leaflet draw has a bug with the rectangle drawing where type is always undefined for some reason
        // This is a hacky fix in order to be able to draw the rectangle at all
        //@ts-expect-error
        window.type = '';

        isDrawing.current = true;

        const drawingLayer = new L.Draw.Rectangle(mapRef.current as DrawMap, {
          shapeOptions: options,
        });

        drawingLayer.enable();

        mapRef.current?.on(L.Draw.Event.CREATED, (e) => {
          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.removeLayer(e.layer as L.Layer);

          const rect = (
            e.layer as L.Rectangle
          ).toGeoJSON() as GeoJSON.Feature<GeoJSON.Polygon>;
          resolve({ rectangle: rect, rectangleMetadata: e.layer });
        });

        mapRef.current?.on(L.Draw.Event.DRAWSTOP, () => {
          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.off('draw:created');
          mapRef.current?.off('draw:drawstop');
        });
      });
    },
    []
  );

  /**
   * drawPolygon allows the user to draw a polygon on the map. When the user
   * completes the shape, the resulting GeoJSON Polygon is resolved by the
   * Promise.
   */
  const drawPolygon = useCallback(
    (
      options: {
        color: string;
        weight: number;
      } = {
        color: 'red',
        weight: 3,
      }
    ) => {
      return new Promise<{
        polygon: GeoJSON.Feature<GeoJSON.Polygon>;
        polygonMetadata: unknown;
      }>((resolve, reject) => {
        if (isDrawing.current) {
          reject('already drawing');
          return;
        }

        isDrawing.current = true;

        const drawingLayer = new L.Draw.Polygon(mapRef.current as DrawMap, {
          shapeOptions: options,
        });

        drawingLayer.enable();

        mapRef.current?.on(L.Draw.Event.CREATED, (e) => {
          const poly = (
            e.layer as L.Polygon
          ).toGeoJSON() as GeoJSON.Feature<GeoJSON.Polygon>;

          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.removeLayer(e.layer as L.Layer);
          const path = poly.geometry.coordinates[0].map((pair) => {
            return { lat: pair[0], lng: pair[1] };
          });

          let IsSelfIntersecting = false;
          path.forEach((_point, index) => {
            if (polygonSelfIntersecting({ full: true, vertex: index }, path)) {
              IsSelfIntersecting = true;
            }
          });

          if (IsSelfIntersecting) {
            const message = clientTranslate(
              'validation.errors.self_intersecting'
            );
            toaster.show({ icon: 'delete', intent: 'danger', message });
            reject('self-intersecting polygon');
            return;
          }

          mapRef.current?.off(L.Draw.Event.CREATED);
          resolve({ polygon: poly, polygonMetadata: e.layer });
        });

        /* DRAW:STOP handles the draw failed case when the polygon is not added to layers.
         setTimeout is a hack to wait for DRAW:CREATED to complete so that isDrawing is updated */
        mapRef.current?.on(L.Draw.Event.DRAWSTOP, () => {
          setTimeout(() => {
            if (isDrawing.current) {
              const errMessage = clientTranslate(
                'validation.errors.failed_drawing',
                { type: 'polygon' }
              );
              toaster.show({
                icon: 'delete',
                intent: 'danger',
                message: errMessage,
              });
            }
            drawingLayer.disable();
            isDrawing.current = false;
            mapRef.current?.off('draw:created');
            mapRef.current?.off('draw:drawstop');
          }, 100);
        });
      });
    },
    []
  );

  /**
   * drawPolyine allows the user to draw a polyline on the map. When the user
   * completes the shape, the resulting GeoJSON polyline is resolved by the
   * Promise.
   */
  const drawPolyline = useCallback(
    (
      options: {
        color: string;
        weight: number;
      } = {
        color: 'red',
        weight: 3,
      }
    ) => {
      return new Promise<{
        polyline: GeoJSON.Feature<GeoJSON.LineString>;
        polylineMetadata: unknown;
      }>((resolve) => {
        if (isDrawing.current) {
          return;
        }

        isDrawing.current = true;

        const drawingLayer = new L.Draw.Polyline(mapRef.current as DrawMap, {
          shapeOptions: options,
        });

        drawingLayer.enable();

        mapRef.current?.on(L.Draw.Event.CREATED, (e) => {
          const poly = (
            e.layer as L.Polyline
          ).toGeoJSON() as GeoJSON.Feature<GeoJSON.LineString>;

          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.removeLayer(e.layer as L.Layer);

          resolve({ polyline: poly, polylineMetadata: e.layer });
        });

        mapRef.current?.on(L.Draw.Event.DRAWSTOP, () => {
          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.off('draw:created');
          mapRef.current?.off('draw:drawstop');
        });
      });
    },
    []
  );

  /**
   * measure allows the user to click two points on the map. When the user
   * completes the clicks, The distance in meters between them is resolved by
   * the promise.
   */
  const measure = useCallback(
    (addLineToMap: boolean) => {
      return new Promise<number>((resolve) => {
        if (isDrawing.current) {
          return;
        }

        isDrawing.current = true;

        const ruler = new L.Draw.Polyline(mapRef.current as DrawMap, {
          shapeOptions: {
            color: 'red',
            weight: 1,
          },
          guidelineDistance: 1,
          maxPoints: 2,
        });

        ruler.enable();

        mapRef.current?.on(L.Draw.Event.CREATED, (e: L.LeafletEvent) => {
          ruler.disable();

          const polyline = (e.layer as L.Polyline).toGeoJSON();
          const coordinates: number[][] = polyline.geometry
            .coordinates as number[][];
          const firstPoint = L.latLng(coordinates[0][1], coordinates[0][0]);
          const secondPoint = L.latLng(coordinates[1][1], coordinates[1][0]);
          const distance = firstPoint.distanceTo(secondPoint);

          addLineToMap &&
            layers.addLayer(
              LineLayerFactory(
                LayerSourceType.GEOMETRY_LAYER,
                clientTranslate('datacosmos.layers.names.line'),
                polyline,
                distance
              )
            );

          isDrawing.current = false;
          mapRef.current?.removeLayer(e.layer as L.Layer);
          resolve(distance);
        });

        mapRef.current?.on(L.Draw.Event.DRAWSTOP, () => {
          mapRef.current?.off('mousemove');
          mapRef.current?.off('draw:drawvertex');
          isDrawing.current = false;
          ruler.disable();
          mapRef.current?.off('draw:created');
          mapRef.current?.off('draw:drawstop');
        });
      });
    },
    [layers]
  );

  const drawCircle = useCallback(
    (
      options: {
        color: string;
        weight: number;
      } = {
        color: 'red',
        weight: 3,
      }
    ): Promise<{
      circleMetadata: unknown;
      center: GeoJSON.Point;
      radius: number;
    }> => {
      return new Promise((resolve) => {
        if (isDrawing.current) {
          return;
        }

        isDrawing.current = true;

        const drawingLayer = new L.Draw.Circle(mapRef.current as DrawMap, {
          shapeOptions: options,
        });

        mapRef.current?.on(L.Draw.Event.CREATED, (e: LeafletEvent) => {
          const newCircle = e.layer.toGeoJSON() as GeoJSON.Feature;
          const center = newCircle.geometry as GeoJSON.Point;
          const radius = e.layer.options.radius / 1000;

          drawingLayer.disable();
          mapRef.current?.removeLayer(e.layer as L.Layer);

          isDrawing.current = false;

          resolve({
            circleMetadata: e.layer,
            center,
            radius,
          });
        });

        mapRef.current?.on(L.Draw.Event.DRAWSTOP, () => {
          drawingLayer.disable();
          isDrawing.current = false;
          mapRef.current?.off('draw:created');
          mapRef.current?.off('draw:drawstop');
        });

        drawingLayer.enable();
      });
    },
    []
  );

  const initialiseMap = useCallback(
    (containerRef?: MutableRefObject<HTMLDivElement | null>) => {
      //Remove previous map
      mapRef.current?.remove();

      const mapContainer = containerRef ?? mapContainerRef;

      if (!mapContainer.current) return undefined;
      mapRef.current = (window.L as typeof L).map(mapContainer.current, {
        zoomControl: false,
        minZoom: config.map.minZoom,
        maxZoom: config.map.maxZoom,
        maxBounds: L.latLngBounds([-90, -210], [90, 210]),
        attributionControl: false,
      });

      new ResizeObserver(() => {
        mapRef.current?.invalidateSize();
      }).observe(mapContainer.current);

      mapRef.current.addLayer(mapBaseLayer.current);

      const zoomCtrl = new DSZoom({
        position: 'bottomleft',
      });
      zoomCtrl.options.containerClasses = 'zoom-controls';
      zoomCtrl.options.zoomInClasses = 'button-zoom button-zoom--in';
      zoomCtrl.options.zoomOutClasses = 'button-zoom button-zoom--out';

      mapRef.current.addControl(zoomCtrl);

      const initialView = config.map.initialView
        .concat(config.map.initialZoom)
        .join(',');
      const mapString = stringToMapView(initialView);
      const location: LatLngExpression = [
        parseFloat(mapString.lat),
        parseFloat(mapString.lng),
      ];
      const zoom = parseInt(mapString.zoom);
      mapRef.current.setView(location, zoom);

      setZoomLevel(mapRef.current.getZoom());
      setCurrentCenter(mapRef.current.getCenter());

      // Subscribe to events
      mapRef.current.on('moveend', () => {
        if (mapRef.current) {
          setZoomLevel(mapRef.current.getZoom());
          setCurrentCenter(mapRef.current.getCenter());
        }
        if (!mapRef.current) return;

        const getRoundNum = (num: number) => {
          const pow10 = Math.pow(10, String(Math.floor(num)).length - 1);
          let d = num / pow10;
          // eslint-disable-next-line no-nested-ternary
          d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;
          return pow10 * d;
        };

        const y = mapRef.current.getSize().y / 2;
        const maxMeters = mapRef.current.distance(
          mapRef.current.containerPointToLatLng([0, y]),
          mapRef.current.containerPointToLatLng([100, y])
        );

        setScale({
          kmValue: getRoundNum(maxMeters) / 1000,
          scalePxWidth: Math.round((100 * getRoundNum(maxMeters)) / maxMeters),
        });
      });

      mapRef.current.on('zoomend', () => {
        if (!mapRef.current) return;
        const layerTooltipPairs: { layer: Element; tooltip: Element }[] = [];
        mapRef.current.eachLayer((mapLayer) => {
          if (!mapLayer.getTooltip()) return;
          document
            .querySelectorAll('.leaflet-tooltip')
            .forEach((tooltip, i) => {
              layerTooltipPairs[i] = { ...layerTooltipPairs[i], tooltip };
            });
          document
            .querySelectorAll('.leaflet-interactive')
            .forEach((layer, i) => {
              layerTooltipPairs[i] = { ...layerTooltipPairs[i], layer };
            });
          // Last element is always minimap, which shouldn't be considered and is therefore removed
          layerTooltipPairs.pop();
        });
      });

      return mapRef.current;
    },
    []
  );

  useEffect(() => {
    if (!mapRef.current || !mapContainerRef.current) return;

    mapRef.current.on('contextmenu', (event) => {
      showMenu(
        event,
        {
          addLayer: layers.addLayer,
          measure,
          drawPolygon,
          drawCircle,
          drawRectangle,
          drawPolyline,
          getFilterContext: () => filtersProvider,
          getLayersContext: () => mapLayers,
        },
        undefined
      );
    });

    mapContainerRef.current.classList.add(`base-layer--${baseLayer.id}`);
  }, [
    baseLayer.id,
    drawCircle,
    drawPolygon,
    drawPolyline,
    drawRectangle,
    filtersProvider,
    layers.addLayer,
    mapLayers,
    measure,
  ]);

  const setNewBaseLayer = useCallback(
    (newLayer: { id: string; url: string; name: string }) => {
      setBaseLayer(newLayer);

      sendInfo({
        type: 'Datacosmos Base layer changed',
        action: 'Change',
        item: 'Base layer',
        module: 'DataCosmos',
        additionalParams: { name: name },
      });
      if (mapContainerRef.current) {
        mapContainerRef.current.className =
          mapContainerRef.current.className.replace(/\bbase-layer--\S*/, '');
        mapContainerRef.current.classList.add(`base-layer--${newLayer.id}`);
      }
      mapRef.current?.removeLayer(mapBaseLayer.current);
      mapBaseLayer.current = L.tileLayer(newLayer.url, {
        maxZoom: config.map.maxZoom,
      });
      mapRef.current?.addLayer(mapBaseLayer.current);
    },
    [sendInfo]
  );

  const removeLegendFromMap = useCallback((idToRemove: string) => {
    if (!mapRef.current) {
      return;
    }
    const legend = document.getElementsByClassName(`legend-${idToRemove}`)[0];
    if (legend?.parentNode) {
      if (legend !== undefined) {
        L.DomUtil.remove(legend as HTMLElement);
      }
    }
  }, []);

  const removeLayerFromMap = useCallback(
    (layer: OCLeafletLayer) => {
      if (!mapRef.current) return;
      const layerId = layer.OCid;
      mapRef.current.removeLayer(layer);
      const p = layerId && mapRef.current.getPane(layerId);
      if (p !== undefined) {
        L.DomUtil.remove(p as HTMLElement);
      }

      // Remove custon pane as in https://github.com/Leaflet/Leaflet/issues/6408
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-prototype-builtins
      if (mapRef.current._panes.hasOwnProperty(layerId)) {
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete mapRef.current._panes[layerId!];
      }
      if (layerId) removeLegendFromMap(layerId);
    },
    [removeLegendFromMap]
  );

  const addLayerToMap = useCallback((layer: OCLeafletLayer, order: number) => {
    if (!mapRef.current) return;
    let pane = layer.OCid ? mapRef.current.getPane(layer.OCid) : undefined;

    if (pane !== undefined) {
      const oldLayer = getOCLayers(mapRef.current).find(
        (l) => l.OCid === layer.OCid
      );
      if (oldLayer !== undefined) {
        mapRef.current.removeLayer(oldLayer);
      }
    } else {
      if (layer.OCid) {
        pane = mapRef.current.createPane(layer.OCid);
      }
      if (pane && layer.OCid) {
        pane.style.zIndex = String(BASE_Z_INDEX + order);
        pane.style.position = 'absolute';
        pane.setAttribute('data-testid', 'oc-map-layer');
        pane.setAttribute('data-oc-layerid', layer.OCid);
      }
    }

    assignPaneToLayer(layer, layer.OCid);
    mapRef.current.addLayer(layer);
  }, []);

  const addLegendToMap = useCallback(
    (layer: SingleBandSTACLayer | BandAlgebraSTACLayer) => {
      if (!mapRef.current) {
        return;
      }

      if (layer?.item.assets) {
        const labels = [];
        const classification = Object.values(layer?.item.assets).find(
          (item) => item?.['classification:classes']
        )?.['classification:classes'];

        if (!classification) {
          return;
        }
        for (const c of classification) {
          const style = c.color_hint;
          const title = c.title ?? c?.name;

          labels.push(
            `<span class='block w-4 h-4' style="background:#${
              style ?? ''
            }"></span>` + title
          );
        }
        const legendControl = new Legend({
          position: 'topleft',
        });
        legendControl.options.labels = labels.join('<br>');
        legendControl.options.legendClassName = 'legend-' + layer.id;
        mapRef.current.addControl(legendControl);
      }
    },
    []
  );

  const removeExistingLegendsFromMap = useCallback(
    (currentLegendLayers: Layer[]) => {
      for (const currentLegendLayer of currentLegendLayers) {
        removeLegendFromMap(currentLegendLayer.id);
      }
    },
    [removeLegendFromMap]
  );

  const editPolygon = useCallback(
    (editableLayer: GeoJSONLayer<unknown> | PolygonLayer) => {
      if (editableLayer.modifiers?.uneditable) {
        return;
      }
      if (
        !editableLayer.leafletLayerMetadata ||
        editableLayer.leafletLayerMetadata === null
      )
        editableLayer.leafletLayerMetadata = newGeoJSONLayer(
          editableLayer.data,
          { style: { fillOpacity: 0, color: 'none' } }
        ).getLayers()[0];

      const editOverlayLayer = editableLayer.getLeafletLayerMetadata();

      addLayerToMap(editOverlayLayer, 1);

      editOverlayLayer.options || (editOverlayLayer.options = {});
      editOverlayLayer.options.editing ||
        (editOverlayLayer.options.editing = {});
      editOverlayLayer.editing?.enable();
      isEditingRef.current = true;

      editOverlayLayer.on('edit', () => {
        const geo = editOverlayLayer.toGeoJSON() as GeoJSON.GeoJSON,
          toReplaceWith =
            editableLayer instanceof GeoJSONLayer
              ? GeoJSONLayerFactory(
                  editableLayer.sourceType,
                  editableLayer.name,
                  geo,
                  editableLayer.metadata,
                  editOverlayLayer,
                  editableLayer.options
                )
              : PolygonLayerFactory(
                  editableLayer.sourceType,
                  editableLayer.name,
                  geo,
                  area(geo as GeoJSON.Feature),
                  editOverlayLayer,
                  editableLayer.options
                );

        addLayer(toReplaceWith);
        removeLayer(editableLayer.id);

        isEditingRef.current = false;
        removeLayerFromMap(editOverlayLayer as OCLeafletLayer);
        editOverlayLayer.editing.disable();
        editOverlayLayer.off('edit');

        if (editableLayer.sourceType === LayerSourceType.APPLICATION_AOI)
          setApplicationAOIs([editOverlayLayer.toGeoJSON()]);
      });
    },
    [
      addLayer,
      addLayerToMap,
      setApplicationAOIs,
      removeLayer,
      removeLayerFromMap,
    ]
  );

  const editCircle = useCallback(
    (circleLayer: CircleLayer) => {
      if (!circleLayer.leafletLayerMetadata) {
        circleLayer.leafletLayerMetadata = L.circle(
          [
            circleLayer.center.coordinates[1],
            circleLayer.center.coordinates[0],
          ],
          circleLayer.radiusInKm * 1000,
          { color: '#e4695e' }
        );
      }
      const drawnCircle = circleLayer.getLeafletLayerMetadata();

      addLayerToMap(drawnCircle, 1);

      drawnCircle.options || (drawnCircle.options = {});
      drawnCircle.options.editing || (drawnCircle.options.editing = {});
      drawnCircle.editing?.enable();

      let toReplaceWith: CircleLayer;

      drawnCircle.on('edit', () => {
        if (toReplaceWith) removeLayer(toReplaceWith.id);

        const drawnCircleCenter: GeoJSON.Point = {
          type: 'Point',
          coordinates: [
            drawnCircle._latlng.lng,
            drawnCircle._latlng.lat,
          ] as GeoJSON.Position,
        };

        const drawnCircleRadiusM: number = drawnCircle._mRadius;

        const drawnCircleAsPolygon = circle(
          drawnCircleCenter,
          drawnCircleRadiusM / 1000
        );

        const drawnCircleArea = area(drawnCircleAsPolygon);

        toReplaceWith = CircleLayerFactory(
          LayerSourceType.GEOMETRY_LAYER,
          circleLayer.name,
          drawnCircleAsPolygon,
          drawnCircleArea,
          drawnCircleRadiusM / 1000,
          drawnCircleCenter,
          drawnCircle,
          circleLayer.options
        );

        addLayer(toReplaceWith);
        removeLayer(circleLayer.id);

        removeLayerFromMap(drawnCircle);
        drawnCircle.editing.disable();
        drawnCircle.off('edit');
      });
    },
    [addLayer, addLayerToMap, removeLayer, removeLayerFromMap]
  );

  const disableEditing = useCallback(
    (layer: any) => {
      if (!layer || isEditingRef) return;
      layer.off('edit');
      layer.off('editstart');
      layer.editing?.disable();
      mapRef.current?.off('mousemove');
      removeLayerFromMap(layer);
    },
    [removeLayerFromMap]
  );

  const newImageOverlayLayer = useCallback((bbox: number[], href: string) => {
    const imageBounds = [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ];
    return L.imageOverlay(
      href,
      imageBounds as L.LatLngBoundsExpression
    ).setZIndex(-2);
  }, []);

  /** getMapBounds gives coordinates for current view port */
  const getMapBounds = useCallback(() => {
    const bounds = mapRef.current?.getBounds();
    if (!bounds) return undefined;
    return [
      bounds.getSouthWest().lng,
      bounds.getSouthWest().lat,
      bounds.getNorthEast().lng,
      bounds.getNorthEast().lat,
    ] as GeoJSON.BBox;
  }, []);

  /**
   * Construct a tiling URL for a single band asset, complete with rescaling where necessary
   * @param cogURL the location of the asset file to display in the tiled layer
   * @param suggestedScale optionally a scale can be set to avoid attempting to calculate it from the COG's metadata
   * @returns a URL from which the tiled form of the asset can be fetched
   */
  const generateSingleBandTilingURL = useCallback(
    async (
      cogURL: string,
      suggestedScale?: [number, number][],
      assetOptions?: ISTACOptions
    ) => {
      let rescale = suggestedScale;

      if (!suggestedScale) {
        const cogMetadata = await tilingApi.fetchMetadataForCOG(cogURL, token);
        const mins = cogMetadata.getValueForAllBands('percentile_1');
        const maxs = cogMetadata.getValueForAllBands('percentile_99');
        rescale = mins.map((min, i) => [min, maxs[i]]);
      }

      return tilingApi.generateSingleBandTilingURL(
        cogURL,
        rescale,
        assetOptions
      );
    },
    [token]
  );

  /**
   * Creates a new Leaflet layer displaying an image from a single STAC asset.
   * For KMLs, GeoJSON and (most) non COG images, returns a layer which overlays
   * the asset directly on the map. For COG images, returns a tiled layer.
   * For anything else, returns undefined.
   * @param item the STAC item containing the asset to display in this layer
   * @param asset the asset from that STAC item to display in this layer
   * @param assetKey the string key identifying the asset in the item's assets field
   * @returns a Leaflet layer displaying the asset, if we know how to create a layer
   *          for this asset type, else undefined
   */
  const newLayerFromAsset = useCallback(
    async (item: IStacItem, asset: IAsset, assetOptions?: ISTACOptions) => {
      if (isAssetKML(asset)) {
        const data = await fetch(asset.href);

        return newKMLLayer(await data.text());
      } else if (isAssetGeoJson(asset)) {
        const data = await fetch(asset.href);

        return newGeoJSONLayer(await data.json());
      } else if (isAssetCloudOptimised(asset)) {
        const HighresUrl = await generateSingleBandTilingURL(
          asset.href,
          item.properties['opencosmos:scale']
            ? [[0, item.properties['opencosmos:scale']]]
            : undefined,
          assetOptions
        );

        const isHighResPermissionGranted: boolean = item?.properties?.[
          'opencosmos:high_resolution_read_permission'
        ] as boolean;

        if (!isHighResPermissionGranted) {
          return newTileLayer(HighresUrl, {
            maxNativeZoom: config.map.maxNativeZoomBasedOnReadPermission,
          });
        }
        return newTileLayer(HighresUrl);
      } else if (isAssetPreviewable(asset)) {
        return newImageOverlayLayer(item.bbox, asset.href);
      }

      return undefined;
    },
    [generateSingleBandTilingURL, newImageOverlayLayer]
  );

  const isEditingRef = useRef(false);

  const handleEditing = useCallback(
    async (
      mapLayer: OCLeafletLayer | Promise<OCLeafletLayer>,
      dcLayer: Layer
    ) => {
      const l = await mapLayer;
      l.off('click');

      if (dcLayer instanceof PolygonLayer) {
        const polygonLayer = dcLayer;
        l.on('click', () => {
          isEditingRef.current = !isEditingRef.current;
          if (isEditingRef.current) {
            editPolygon(polygonLayer);
          } else {
            disableEditing(polygonLayer.getLeafletLayerMetadata());
          }
        });
      } else if (dcLayer instanceof CircleLayer) {
        const circleLayer = dcLayer;
        l.on('click', () => {
          editCircle(circleLayer);
        });
      } else if (
        dcLayer instanceof GeoJSONLayer &&
        dcLayer.sourceType === LayerSourceType.TASKING_REGIONS
      ) {
        const regionLayer = dcLayer;
        l.on('click', () => {
          disableEditing(regionLayer.getLeafletLayerMetadata());
        });
      }
    },
    [disableEditing, editCircle, editPolygon]
  );

  const addLayersContextMenu = useCallback(
    async (
      dcLayer: Layer,
      leafletLayer: OCLeafletLayer | Promise<OCLeafletLayer>
    ) => {
      const l = await leafletLayer;

      l.on('contextmenu', (event) => {
        L.DomEvent.stopPropagation(event);
        showMenu(
          event,
          {
            selectLayer: (layer: Layer, addToCurrentSelection: boolean) => {
              if (!isLayersMenuOpen) setLayersMenuOpen(true);
              selectLayer(layer, addToCurrentSelection);
            },
          },
          dcLayer
        );
      });
    },
    [isLayersMenuOpen, selectLayer, setLayersMenuOpen]
  );

  const addPlaceHolderLayer = useCallback(
    (dcLayer: { id: string; item: IStacItem }, order: number) => {
      const layer: OCLeafletLayer = newOutlineLayer(dcLayer.item, {
        style: {
          opacity: 0,
        },
      });

      layer.OCid = dcLayer.id;
      addLayerToMap(layer, order);
      if (isLayersComparisonToggled) {
        updateComparisonGroups(
          [layer.OCid, ...layersComparisonGroups[0]],
          layersComparisonGroups[1]
        );
      }
      return layer;
    },
    [addLayerToMap, isLayersComparisonToggled, layersComparisonGroups]
  );

  const addNewLayerToMap = useCallback(
    async (dcLayer: Layer, order: number) => {
      let layer: OCLeafletLayer | undefined;

      if (dcLayer instanceof OutlineLayer) {
        const outlineLayer = dcLayer;
        layer = newOutlineLayer(outlineLayer.item, { style: { color: 'red' } });
      } else if (dcLayer instanceof SingleBandSTACLayer) {
        const assetLayer = dcLayer;
        // newLayerFromAsset might take some time, we add a placeholder meanwhile
        addPlaceHolderLayer(assetLayer, order);

        assetLayer.generateColorFormula();
        assetLayer.setSettingBrightnessOff();
        assetLayer.setSettingSaturationOff();
        assetLayer.setSettingContrastOff();

        layer = (await newLayerFromAsset(
          assetLayer.item,
          assetLayer.getAsset(),
          assetLayer.options as unknown as ISTACOptions
        )) as unknown as OCLeafletLayer | undefined;
      } else if (dcLayer instanceof BandAlgebraSTACLayer) {
        const algebraLayer = dcLayer;
        // newLayerFromAsset might take some time, we add a placeholder meanwhile
        addPlaceHolderLayer(algebraLayer, order);
        layer = newLayerFromExpression(
          algebraLayer.item,
          dcLayer.expression,
          dcLayer.options.scale,
          dcLayer.options.colormap,
          dcLayer.options.bandAlgebraType,
          dcLayer.options.rgbExpression,
          dcLayer.options.rescaleFalse
        );
      } else if (
        dcLayer instanceof GeoJSONLayer ||
        dcLayer instanceof OpportunityLayer ||
        dcLayer instanceof FieldOfRegardLayer ||
        dcLayer instanceof SwathLayer
      ) {
        const geoJSONlayer = dcLayer as GeoJSONLayer<unknown>;
        layer = newGeoJSONLayer(geoJSONlayer.data);

        layer = newGeoJSONLayer(dcLayer.data, {
          onEachFeature: (__, l) => {
            if (dcLayer.sourceType !== LayerSourceType.ASSET_OUTLINE) {
              l.bindPopup(null as unknown as Content, {
                className: 'customPopup',
              });
              l.on('mouseover', (e) => {
                if (mapRef.current) {
                  l.getPopup()?.setLatLng(e.latlng).openOn(mapRef.current);
                }
              });
              l.on('mouseout', () => l.closePopup());
            }
          },
        });
      } else if (dcLayer instanceof PolygonLayer) {
        const polygonLayer = dcLayer;

        layer = newGeoJSONLayer(polygonLayer.data, {
          onEachFeature: (__, l) => {
            l.bindPopup(polygonLayer.getPopupContent(), {});
            l.on('mouseover', (e) => {
              if (mapRef.current) {
                l.getPopup()?.setLatLng(e.latlng).openOn(mapRef.current);
              }
            });
            l.on('mouseout', () => l.closePopup());
          },
        });
      } else if (dcLayer instanceof LineLayer) {
        layer = newGeoJSONLayer(dcLayer.data);
      } else if (dcLayer instanceof CircleLayer) {
        const circleLayer = dcLayer;
        layer = newGeoJSONLayer(circleLayer.data, {
          onEachFeature: (__, l) => {
            l.bindPopup(circleLayer.getPopupContent(), {});
            l.on('mouseover', (e) => {
              if (mapRef.current) {
                l.getPopup()?.setLatLng(e.latlng).openOn(mapRef.current);
              }
            });
            l.on('mouseout', () => l.closePopup());
          },
        });
      } else if (dcLayer instanceof VideoLayer) {
        layer = newVideoLayer(dcLayer);
      } else if (dcLayer instanceof MapMarkerLayer) {
        layer = newMapMarkerLayer(dcLayer);

        layer.on('add', (e) => {
          if (dcLayer.options.enablePopup) {
            (e.target as L.Layer).bindPopup(dcLayer.getPopupContent());
            (e.target as L.Layer).openPopup();
          }
        });
      } else if (dcLayer instanceof PointLayer) {
        layer = newPointLayer(dcLayer, {
          color: dcLayer.options.color,
          fillColor: dcLayer.options.color,
        });
      } else {
        throw new Error('Map does not support this layer');
      }

      if (layer) {
        layer.OCid = dcLayer.id;
        addLayerToMap(layer, order);
        if (
          ![
            LayerSourceType.ASSET_OUTLINE,
            LayerSourceType.PIXEL_MODE_MARKER,
          ].includes(dcLayer.sourceType) &&
          layersComparisonGroups !== null
        ) {
          updateComparisonGroups(
            [layer.OCid, ...layersComparisonGroups[0]],
            layersComparisonGroups[1]
          );
        }
      }

      return layer;
    },
    [
      addLayerToMap,
      addPlaceHolderLayer,
      newLayerFromAsset,
      layersComparisonGroups,
    ]
  );

  const updateOptions = useCallback(
    async (
      layer: OCLeafletLayer | Promise<OCLeafletLayer>,
      options: ILayerOptions & Partial<IGeoJSONLayerOptions>,
      order: number
    ) => {
      const l = await layer;
      const p = mapRef.current?.getPane(l.OCid!);

      const o = options.opacity === 0 ? -order : order;

      if (p) {
        p.style.display = options.visible ? 'block' : 'none';
        p.style.opacity = String(options.opacity);
        p.style.zIndex = options.zIndex
          ? String(options.zIndex)
          : String(BASE_Z_INDEX + o);
        p.style.mixBlendMode = options.blendMode;
      }

      if (_.isFunction((l as L.LayerGroup).eachLayer)) {
        (l as L.LayerGroup).eachLayer((gl) => {
          // Set the colors if the map layer AND if the state layer support it
          if (
            (gl as L.GeoJSON | undefined)?.setStyle !== undefined &&
            options.color !== undefined
          ) {
            (gl as L.GeoJSON).setStyle({
              color: options.color,
              weight: options.weight,
              fillOpacity: options.fillOpacity,
            });
          }
        });
      }
    },
    []
  );

  const panTo = useCallback((lat: number, lng: number) => {
    mapRef.current?.panTo(new L.LatLng(lat, lng));
  }, []);

  const onMapZoomEnd = useCallback((handler: () => void) => {
    mapRef.current?.on('zoomend', handler);
  }, []);

  const onMapMouseMove = useCallback(
    (cb: (e: MouseEvent, coords: { lat: number; lng: number }) => void) => {
      mapRef.current?.on('mousemove', (e: LeafletMouseEvent) => {
        cb(e.originalEvent, e.latlng);
      });
    },
    []
  );

  useEffect(() => {
    mapRef.current?.removeLayer(mapBaseLayer.current);
    const newBaseLayer = L.tileLayer(
      (isDarkmode ? baseLayers[1] : baseLayers[0]).url,
      { maxZoom: config.map.maxZoom }
    );
    mapRef.current?.addLayer(newBaseLayer);
  }, [isDarkmode]);

  useEffect(() => {
    const mapBounds = getMapBounds();
    if (!layers.layers || !mapBounds) {
      return;
    }

    const classificationLayers = layers.layers.filter(
      (cLayer) =>
        cLayer instanceof SingleBandSTACLayer &&
        Object.values(cLayer.item.assets).some((item) =>
          JSON.stringify(item).includes('classification:classes')
        )
    ) as SingleBandSTACLayer[];

    const currentClassificationItemInView = classificationLayers.find(
      (cl) =>
        (booleanWithin(bboxPolygon(mapBounds), bboxPolygon(cl.item.bbox)) ||
          booleanWithin(bboxPolygon(cl.item.bbox), bboxPolygon(mapBounds))) &&
        cl.options.visible
    );

    if (currentClassificationItemInView) {
      removeExistingLegendsFromMap(layers.layers);
      const isLegendPresent = document.getElementsByClassName(
        `legend-${currentClassificationItemInView.id}`
      )[0];
      if (!isLegendPresent) {
        addLegendToMap(currentClassificationItemInView);
      }
    }
  }, [
    layers.layers,
    removeExistingLegendsFromMap,
    addLayerToMap,
    getMapBounds,
    addLegendToMap,
  ]);

  const handleDisplayZoomAlert = () => {
    toaster.showCompositeToast({
      title: clientTranslate(
        'datacosmos.map.zoomAlertForLowReslutionImages.title'
      ),
      description: clientTranslate(
        'datacosmos.map.zoomAlertForLowReslutionImages.description'
      ),
      intent: 'warning',
      icon: 'warning-sign',
    });
  };

  useEffect(() => {
    if (!layers.layers.length) {
      return;
    }

    const mapBounds = getMapBounds();
    if (!mapBounds) {
      return;
    }

    const nonHighResLayers = layers.layers.filter(
      (cLayer) =>
        cLayer instanceof SingleBandSTACLayer &&
        !cLayer.item.properties['opencosmos:high_resolution_read_permission']
    ) as SingleBandSTACLayer[];

    if (!nonHighResLayers.length) {
      return;
    }

    const currentItemInView = nonHighResLayers.find(
      (cl) =>
        (booleanWithin(bboxPolygon(mapBounds), bboxPolygon(cl.item.bbox)) ||
          booleanWithin(bboxPolygon(cl.item.bbox), bboxPolygon(mapBounds))) &&
        cl.options.visible
    );

    if (!currentItemInView) {
      return;
    }

    if (Number(zoomLevel) > config.map.maxNativeZoomBasedOnReadPermission) {
      if (currentItemInView.options?.isLowResolutionZoomAlertShown) {
        return;
      }
      handleDisplayZoomAlert();
      currentItemInView.options.isLowResolutionZoomAlertShown = true;
      return;
    }

    return;
  }, [zoomLevel, layers.layers, getMapBounds]);

  const setViewToFitBbox = useCallback((bbox: number[], maxZoom?: number) => {
    if (
      !isFinite(bbox[0]) ||
      !isFinite(bbox[1]) ||
      !isFinite(bbox[2]) ||
      !isFinite(bbox[3])
    ) {
      return;
    }
    const bounds = [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ] as LatLngBoundsExpression;
    mapRef.current?.fitBounds(bounds, {
      maxZoom: maxZoom,
    });
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;
    const currentLayers = getOCLayers(mapRef.current);

    for (const [i, dcLayer] of layers.layers.entries()) {
      let mapLayer: OCLeafletLayer | Promise<OCLeafletLayer> =
        currentLayers.find((layer) => layer.OCid === dcLayer.id) as
          | OCLeafletLayer
          | Promise<OCLeafletLayer>;
      // First layers go on top, hence, have a bigger order number
      const order = layers.layers.length - i;

      // Find layers that should be added
      if (mapLayer === undefined) {
        mapLayer = addNewLayerToMap(dcLayer, order) as
          | OCLeafletLayer
          | Promise<OCLeafletLayer>;
      }

      // Update options
      void updateOptions(mapLayer, dcLayer.options, order);

      void setPopupContent(mapLayer, dcLayer);

      void handleEditing(mapLayer, dcLayer);

      void addLayersContextMenu(dcLayer, mapLayer);

      if (
        dcLayer instanceof SingleBandSTACLayer &&
        (dcLayer.isSettingBrightness() ||
          dcLayer.isSettingSaturation() ||
          dcLayer.isSettingContrast())
      ) {
        void addNewLayerToMap(dcLayer, order);
      }
    }

    // Find layers that should be removed
    for (const layer of currentLayers) {
      const found = layers.layers.some((dcLayer) => layer.OCid === dcLayer.id);
      if (!found) {
        removeLayerFromMap(layer);
        setLayersComparisonGroups((prev) => {
          if (prev === null) return prev;
          return [
            prev[0].filter((l) => l !== layer.OCid),
            prev[1].filter((l) => l !== layer.OCid),
          ];
        });
      }
    }
  }, [
    addLayersContextMenu,
    addNewLayerToMap,
    handleEditing,
    layers.layers,
    removeLayerFromMap,
    updateOptions,
  ]);

  const updateComparisonGroups = (left: string[], right: string[]) => {
    const leftLeafletLayers = getLayersFromArrayOfIds(mapRef.current, left);
    const rightLeafletLayers = getLayersFromArrayOfIds(mapRef.current, right);
    setLayersComparisonGroups([left, right]);
    if (comparisonControlRef.current) {
      comparisonControlRef.current.updateLayers(
        leftLeafletLayers,
        rightLeafletLayers
      );
    } else {
      comparisonControlRef.current = L.control
        //@ts-expect-error - SplitMap util is not typed
        .splitMap(leftLeafletLayers, rightLeafletLayers)
        .addTo(mapRef.current);
    }
  };

  const toggleLayersComparison = (selected?: Layer[]) => {
    if (layersComparisonGroups) {
      if (comparisonControlRef.current) {
        mapRef.current?.removeControl(comparisonControlRef.current);
        comparisonControlRef.current = null;
      }
      setLayersComparisonGroups(null);
    } else {
      let leftLayers, rightLayers;
      if (selected?.length) {
        leftLayers = selected;
        rightLayers = layers.layers.filter((l) => !l.options.isSelected);
      } else {
        leftLayers = [layers.layers?.[0]];
        rightLayers = layers.layers?.slice(1);
      }
      updateComparisonGroups(
        leftLayers.map((l) => l.id),
        rightLayers.map((l) => l.id)
      );
    }
  };

  return {
    panTo,
    setViewToFitBbox,
    initialiseMap,
    baseLayer,
    setBaseLayer: setNewBaseLayer,
    onMapZoomEnd,
    measure,
    drawPolygon,
    getMapBounds,
    editPolygon,
    scale,
    disableEditing,
    zoomLevel,
    currentCenter,
    setZoomAndCenter,
    drawCircle,
    onMapMouseMove,
    mapRef,
    mapContainerRef,
    drawRectangle,
    drawPolyline,
    layersComparisonGroups,
    isLayersComparisonToggled,
    toggleLayersComparison,
    updateComparisonGroups,
    mapBaseLayers,
    setMapBaseLayers,
    handleDisplayZoomAlert,
  };
};

export const MapProvider = ({
  catalog,
  layers,
  children,
  projects,
  applicationCatalog,
}: {
  children: React.ReactNode;
} & IHookArgs) => {
  return (
    <MapContext.Provider
      value={useMapHook({ catalog, layers, projects, applicationCatalog })}
    >
      {children}
    </MapContext.Provider>
  );
};
