import { Dialog } from '@blueprintjs/core';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';
import type { Activity } from 'api/activities/types';
import type { TaskingRequest } from 'api/tasking/types';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import moment from 'moment';
import { roundToNearestSecond } from 'utils/common/dateUtils';
import { MapController } from 'datacosmos';
import {
  MapLayersProvider,
  useMapLayers,
} from 'datacosmos/stores/MapLayersProvider';
import { ApplicationCatalogProvider } from 'datacosmos/stores/ApplicationProvider';
import { ImageCatalogProvider } from 'datacosmos/stores/ImageCatalogProvider';
import { ProjectProvider } from 'datacosmos/stores/ProjectProvider';
import FiltersProvider from 'datacosmos/stores/FiltersProvider';
import { useActivitiesMap } from '../../hooks/useActivitiesMap';
import type { SwathControlData } from 'api/tasking/helpers';

//TODO: Replace with the actual call to mission configuration service when it becomes available
import missionConfigProd from '__mock__/mock_mission_configs_production.json';
import missionConfigTest from '__mock__/mock_mission_configs_test.json';
import type { MissionConfiguration } from 'api/missionConfig/types';
import Button from '_molecules/Button/Button';
import { DetailsItem } from '../../../shared/components/DetailsItem';
import {
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
} from 'datacosmos/utils/geojson';
import { useSwathControlData } from 'datacosmos/utils/hooks/useSwathControlData';
import { isDevMode } from 'utils/common/CommonUtils';
import { TaskingLegend } from 'datacosmos/components/TaskingLegend';

//TODO: Replace with the actual call to mission configuration service when it becomes available
const getMissionConfig = (missionId: string) => {
  let conf: MissionConfiguration;

  if (isDevMode()) {
    conf = missionConfigTest as MissionConfiguration;
  } else {
    conf = missionConfigProd as MissionConfiguration;
  }
  return conf.configs.find((c) => c.core.mission_id === missionId);
};

type Props = {
  isMapOpen: boolean;
  setIsMapOpen: Dispatch<SetStateAction<boolean>>;
  activities: Activity[] | undefined;
  request: TaskingRequest | undefined;
  activityToModify: Activity;
  refetchActivities: () => Promise<void>;
};

const FullscreenMapInner = ({
  isMapOpen,
  setIsMapOpen,
  activities,
  request,
  activityToModify,
  refetchActivities,
}: Props) => {
  const config = getMissionConfig(activityToModify.mission_id);

  const container = useRef<HTMLDivElement | null>(null);

  const { layers } = useMapLayers();

  const { debouncedSetSwathData, setSwathControlData, swathControlData } =
    useSwathControlData({
      area:
        request && activityToModify.parameters.physical?.latest
          ? getRegionOfInterestCoverageArea(
              [activityToModify.parameters.physical.latest.geojson],
              [request.region]
            )
          : 0,
      coverage:
        request && activityToModify.parameters.physical?.latest
          ? getRegionOfInterestCoveragePercent(
              [activityToModify.parameters.physical.latest.geojson],
              [request.region]
            )
          : 0,
      duration: {
        end: moment.utc(activityToModify.end_date).toDate(),
        start: moment.utc(activityToModify.start_date).toDate(),
      },
      rotation: activityToModify.parameters?.platform?.roll_angle ?? 0,
      sza: activityToModify.parameters.physical?.latest?.midpoint.sza_deg,
      oza: activityToModify.parameters.physical?.latest?.midpoint.oza_deg,
      parameters: activityToModify.parameters,
    });

  const {
    initMap,
    mapRef,
    modifyActivitySwath,
    updateActivityWithNewSwathParams,
  } = useActivitiesMap(container, activities, request, activityToModify);

  const handlActivitySwathModifying = async (data: SwathControlData) => {
    const swath = await modifyActivitySwath(activityToModify, data);
    if (!swath) return;
    setSwathControlData((control) => ({
      ...control,
      coverage: swath.aoi_coverage!,
      area: swath.area_km2,
      oza: swath.midpoint.oza_deg,
      sza: swath.midpoint.sza_deg,
    }));
  };

  // Set the activity's swath control data when the activity to modify changes
  useEffect(() => {
    if (activityToModify) {
      setSwathControlData((control) => ({
        ...control,
        duration: {
          start: moment.utc(activityToModify.start_date).toDate(),
          end: moment.utc(activityToModify.end_date).toDate(),
        },
        rotation: activityToModify.parameters?.platform?.roll_angle ?? 0,
        sza: activityToModify.parameters?.physical?.latest?.midpoint.sza_deg,
        oza: activityToModify.parameters?.physical?.latest?.midpoint.oza_deg,
        parameters: activityToModify.parameters,
      }));
    }
  }, [activityToModify, setSwathControlData]);

  return (
    <div>
      <Dialog
        isOpen={isMapOpen}
        className="w-[97%] h-[95vh] z-40 p-0"
        title=""
        onClose={() => {
          setIsMapOpen(false);
          mapRef.current = undefined;
          void refetchActivities();
        }}
        onOpened={() => {
          initMap();
        }}
        isCloseButtonShown={true}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 3fr',
          }}
        >
          <div className="p-4 flex flex-col gap-4">
            <table className="w-full">
              <colgroup>
                <col style={{ width: '20%' }}></col>
                <col style={{ width: '60%%' }}></col>
                <col style={{ width: '20%' }}></col>
              </colgroup>
              <thead>
                <th className="text-center">Start</th>
                <th className="text-center">Duration</th>
                <th className="text-center">End</th>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">
                    {moment.utc(activityToModify.start_date).format('HH:mm:ss')}
                  </td>
                  <td>
                    <RangeSlider
                      numberOfHandles={2}
                      step={1000}
                      minValue={moment(activityToModify.start_date)
                        .utc()
                        .subtract(180, 'seconds')
                        .valueOf()}
                      maxValue={moment(activityToModify.end_date)
                        .utc()
                        .add(180, 'seconds')
                        .valueOf()}
                      value={[
                        swathControlData.duration.start.valueOf(),
                        swathControlData.duration.end.valueOf(),
                      ]}
                      onChange={(val) => {
                        const newData = {
                          ...swathControlData,
                          duration: {
                            start: moment(val[0]).utc().toDate(),
                            end: moment(val[1]).utc().toDate(),
                          },
                        };

                        debouncedSetSwathData(newData, () => {
                          void handlActivitySwathModifying(newData);
                        });
                      }}
                      showValuesAboveHandles
                      handleLabelFormatter={(value) => {
                        return moment(
                          roundToNearestSecond(moment.unix(value / 1000))
                        )
                          .utc()
                          .format('mm:ss');
                      }}
                      showScale={false}
                    />
                  </td>
                  <td className="text-right">
                    {moment.utc(activityToModify.end_date).format('HH:mm:ss')}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="w-full">
              <colgroup>
                <col style={{ width: '20%' }}></col>
                <col style={{ width: '60%' }}></col>
                <col style={{ width: '20%' }}></col>
              </colgroup>
              <thead>
                <th className="text-center"></th>
                <th className="text-center">Roll angle</th>
                <th className="text-center"></th>
              </thead>

              <tbody>
                <tr>
                  <td className="text-left">
                    Min: {config?.platform.roll_steering[0]}
                  </td>
                  <td>
                    <RangeSlider
                      numberOfHandles={1}
                      step={0.1}
                      minValue={config?.platform.roll_steering[0]}
                      maxValue={config?.platform.roll_steering[1]}
                      value={[swathControlData.rotation]}
                      showValuesAboveHandles
                      showScale={false}
                      onChange={(val) => {
                        debouncedSetSwathData(
                          {
                            ...swathControlData,
                            rotation: val[0],
                          },
                          () => {
                            void handlActivitySwathModifying({
                              ...swathControlData,
                              rotation: val[0],
                            });
                          }
                        );
                      }}
                    />
                  </td>
                  <td className="text-right">
                    Max: {config?.platform.roll_steering[1]}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex flex-col gap-2">
              <DetailsItem
                title="SZA:"
                value={
                  swathControlData.sza
                    ? `${swathControlData.sza.toFixed(2)}°`
                    : '-'
                }
              />
              <DetailsItem
                title="OZA:"
                value={
                  swathControlData.oza
                    ? `${swathControlData.oza.toFixed(2)}°`
                    : '-'
                }
              />
              <DetailsItem
                title="Area:"
                value={`${swathControlData.area.toFixed(2)}km2`}
              />
              <DetailsItem
                title="AOI coverage: "
                value={`${swathControlData.coverage.toFixed(2)}%`}
              />
            </div>

            <Button
              text="Update activity"
              onPress={async () => {
                await updateActivityWithNewSwathParams(
                  activityToModify,
                  swathControlData
                );
              }}
            />
          </div>
          <div>
            <TaskingLegend
              showOnRoute="/ops/mission/:mission/schedule/activity/:activity"
              layers={layers}
              rightMenuOpen={false}
            />
            <div
              ref={container}
              id="fullscreen-activities-map"
              className={'w-full z-10 h-[90vh]'}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const FullscreenMap = (props: Props) => {
  return (
    <MapLayersProvider>
      <FiltersProvider>
        <ImageCatalogProvider>
          <ProjectProvider>
            <ApplicationCatalogProvider>
              <MapController>
                <FullscreenMapInner {...props} />
              </MapController>
            </ApplicationCatalogProvider>
          </ProjectProvider>
        </ImageCatalogProvider>
      </FiltersProvider>
    </MapLayersProvider>
  );
};

export default FullscreenMap;
