import React from 'react';
import { Button, NonIdealState, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ICommandHistory } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import s from './index.module.scss';
import { useTelecommandsApi } from 'services/api/ops/commands/useTelecommandsApi';

export interface IProps {
  currentHistoryResponse?: ICommandHistory;
  replaceCommandNote: (note: string) => void;
}

const Metadata = (props: IProps) => {
  const { saveCommandNote } = useTelecommandsApi();
  const { currentHistoryResponse, replaceCommandNote } = props;

  if (!currentHistoryResponse) {
    return (
      <NonIdealState
        icon={IconNames.ANNOTATION}
        title="Choose item from history."
      />
    );
  }

  return (
    <div>
      {currentHistoryResponse && (
        <ul className={s.metadataPropertiesList}>
          <li>
            <div className={s.metadataHeader}>Custom Name</div>
            <div>{currentHistoryResponse.customCommandName}</div>
          </li>
          <li>
            <div className={s.metadataHeader}>Procedure Name</div>
            <div>{currentHistoryResponse.procedureName}</div>
          </li>
          <li>
            <div className={s.metadataHeader}>Terminal Session Sequence ID</div>
            <div>{currentHistoryResponse.uuid}</div>
          </li>
          <li>
            <div className={s.metadataHeader}>Created At</div>
            <div>{currentHistoryResponse.createdAt}</div>
          </li>
          <li>
            <div className={s.metadataHeader}>Sent By</div>
            <div>{currentHistoryResponse.subjectID}</div>
          </li>
          <li>
            <div className={s.metadataHeader}>Satellite Sequence ID</div>
            <div>{currentHistoryResponse.satelliteSequenceID}</div>
          </li>
        </ul>
      )}

      <form className={s.metadataNotesContainer}>
        <label>
          <div className={s.noteLabel}>Notes</div>
          <TextArea
            className={s.verticalElement}
            id="notesArea"
            value={currentHistoryResponse.note}
            onChange={(e) => replaceCommandNote(e.target.value)}
          />
        </label>
        <Button
          onClick={() => {
            void saveCommandNote(
              currentHistoryResponse.uuid ?? '',
              currentHistoryResponse.note
            );
          }}
        >
          Save Note
        </Button>
      </form>
    </div>
  );
};

export default Metadata;
