import { useEffect, useRef, useState, Fragment } from 'react';
import { capitalize } from 'lodash';
import s from './index.module.scss';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import IconButton from '_molecules/IconButton/IconButton';
import Input from '_molecules/Input/Input';
import Fuse from 'fuse.js';
import type { AppTags, IApplication } from 'datacosmos/types/applications';
import { APP_TAGS_LIST } from 'datacosmos/types/applications';
import List from '_molecules/List/List';
import { Item, Section } from 'react-stately';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { DATACOSMOS_APPLICATION_SUITE } from 'env';

export default function Applications() {
  const { applicationList } = useApplicationCatalog();
  const { setActivePage } = useActivePage();
  const { translate } = useLocalisation();

  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [filteredApps, setFilteredApps] =
    useState<IApplication[]>(applicationList);

  const [selectedTags, setSelectedTags] = useState<AppTags[]>([]);
  const [query, setQuery] = useState<string>('');

  const showTagSearch = DATACOSMOS_APPLICATION_SUITE !== 'conida';

  const getAppsByTags = (apps: IApplication[]) => {
    if (selectedTags.length > 0) {
      return apps.filter((app) =>
        app.tags.some((t) => selectedTags.some((st) => st === t))
      );
    }

    return apps;
  };

  const getAppsBySearchTerm = (apps: IApplication[], query: string) => {
    const fuse = new Fuse(apps, {
      keys: [
        'name',
        'shortDescription',
        'description',
        'provider.name',
        'tags',
      ],
      threshold: 0.4,
    });
    if (query === null || query === '') {
      return apps;
    }

    return fuse.search(query);
  };

  const getFilteredApps = () => {
    const byTags = getAppsByTags(applicationList);
    const byQuery = getAppsBySearchTerm(applicationList, query);
    return byTags.filter((t) => byQuery.some((q) => q === t));
  };

  useEffect(() => {
    setFilteredApps(getFilteredApps());
  }, [selectedTags, query]);

  return (
    <>
      <SidebarHeader
        title={
          <div className="flex items-center gap-3">
            <span>{translate('datacosmos.applications.title')}</span>
            <IconButton
              icon="MagnifyingGlass"
              onPress={() => setIsSearchExpanded(!isSearchExpanded)}
              size={24}
              active={isSearchExpanded}
              inherit={false}
              iconClassName="!stroke-header-sidebar-contrast !dark:stroke-header-sidebar-dark-contrast"
            />
          </div>
        }
        rightIcon={{
          iconName: 'Expand',
          onIconClick: () => setActivePage(undefined),
        }}
      />

      {isSearchExpanded && (
        <div
          className="absolute w-64 color-item p-3 flex flex-col gap-3"
          ref={sidebarRef}
          style={{
            left: '-256px',
            top: '60px',
          }}
        >
          <div className="flex items-center justify-between">
            <span>{translate('datacosmos.views.search')}</span>
            <IconButton
              icon="Expand"
              onPress={() => setIsSearchExpanded(false)}
              size={24}
            />
          </div>
          <Input
            type="text"
            placeholder={translate(
              'datacosmos.applications.global.search.placeholder'
            )}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            className="border-item-contrast dark:border-item-dark-contrast"
          />

          {showTagSearch && (
            <List
              selectionMode="multiple"
              onSelectionChange={(key) => {
                setSelectedTags([...key] as AppTags[]);
              }}
            >
              <Section title="Tags">
                {APP_TAGS_LIST.sort((a, b) => a.localeCompare(b)).map((tag) => (
                  <Item key={tag}>
                    {capitalize(tag.valueOf().replace(/\_/g, ' '))}
                  </Item>
                ))}
              </Section>
            </List>
          )}
        </div>
      )}

      <div
        id="datacosmos-applications-sidebar-body"
        className={s.groupContainer}
      >
        {filteredApps.map((app) => {
          return <Fragment key={app.id}>{app.renderer(app)}</Fragment>;
        })}
      </div>
    </>
  );
}
