import { Button, Icon, Intent, MenuItem, Switch } from '@blueprintjs/core';
import type { ItemRenderer } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import React, { useState } from 'react';
import Endpoint, { Connectors } from './Endpoint';
import s from './index.module.scss';
import { IconNames } from '@blueprintjs/icons';
import useUpdateEffect from 'utils/hooks/useUpdateEffect';
import type {
  Endpoint as EndpointType,
  MissionTopology,
} from 'api/gateway/types';

interface IProps {
  appName: string;
  apps: MissionTopology;
  setApps: React.Dispatch<React.SetStateAction<MissionTopology | undefined>>;
  moreContent: string[];
  setMoreContent: React.Dispatch<React.SetStateAction<string[]>>;
  setShouldDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

enum AppOwner {
  UNKNOWN = 'Select',
  CUSTOMER = 'customer',
  PLATFORM = 'platform',
}

const VALID_OWNERS = [AppOwner.CUSTOMER, AppOwner.PLATFORM];

const OwnerSelect = Select.ofType<AppOwner>();

const AppCard = ({
  appName,
  apps,
  setApps,
  moreContent,
  setMoreContent,
  setShouldDisableSaveButton,
}: IProps) => {
  const [name, setName] = useState<string>(appName);

  const [selectedOwner, setSelectedOwner] = useState<AppOwner>(
    apps?.[name]?.owner ? (apps[name].owner as AppOwner) : AppOwner.UNKNOWN
  );

  const [bypass, setBypass] = useState<boolean>(
    Boolean(apps[name]?.bypass_decoding_protocol)
  );

  const [appEndpoints, setAppEndpoints] = useState<
    (EndpointType & { id?: number })[]
  >(apps[name]?.endpoints.map((end, i) => ({ ...end, id: i })));

  const [error, setError] = useState<string>();

  const handleAddEndpoint = (newEndpoint: EndpointType) => {
    setAppEndpoints([...appEndpoints, newEndpoint]);
  };

  const handleModifyEndpoint = (
    modified: EndpointType,
    endpointIndex: number
  ) => {
    setAppEndpoints((prev) => {
      prev[endpointIndex] = modified;
      return [...prev];
    });
  };

  const handleDeleteEndpoint = (endpointIndex: number) => {
    setAppEndpoints(appEndpoints.filter((end, i) => endpointIndex !== i));
  };

  const handleConnectorDuplicates = (connector: Connectors) => {
    return appEndpoints.some((end) => end && end.when.connector === connector);
  };

  const anyConnectorSelectedHandler = (selectedEndpoint: EndpointType) => {
    if (selectedEndpoint.when.connector === Connectors.ANY) {
      setAppEndpoints([selectedEndpoint]);
    }
  };

  const ownerRenderer: ItemRenderer<AppOwner> = (owner, { handleClick }) => {
    const selected = owner === selectedOwner;

    return (
      <MenuItem
        key={owner}
        onClick={(e) => {
          handleClick(e);
        }}
        active={selected}
        text={owner}
      />
    );
  };

  useUpdateEffect(() => {
    if (Object.keys(apps).some((a) => a === '')) {
      setShouldDisableSaveButton(true);
      setError("Name can't be empty");
    }

    if (appName === name) {
      setApps((prev) => {
        return {
          ...prev,
          [appName]: {
            owner: selectedOwner,
            bypass_decoding_protocol: bypass,
            endpoints: appEndpoints,
          },
        };
      });
    }
  }, [appEndpoints, selectedOwner, bypass, name]);

  useUpdateEffect(() => {
    if (name.length <= 0) {
      setShouldDisableSaveButton(true);
      setError("Name can't be empty");
      return;
    }

    const appsArrayOfObjects = Object.entries(apps).map((o) => {
      return { appName: o[0], value: o[1] };
    });

    const indexOfSameName = appsArrayOfObjects.findIndex(
      (app) => app.appName === appName
    );
    const newAppName = {
      appName: name,
      value: appsArrayOfObjects[indexOfSameName].value,
    };

    appsArrayOfObjects.splice(indexOfSameName, 1, newAppName);

    const finalAppObject = appsArrayOfObjects.reduce((obj, app) => {
      return Object.assign(obj, { [app.appName]: app.value }, {});
    }, {});

    if (Object.keys(apps).includes(name)) {
      setShouldDisableSaveButton(true);
      setError('Duplicate name');
    } else {
      setShouldDisableSaveButton(false);
      setError(undefined);
      setApps(finalAppObject);
    }
  }, [name]);

  return (
    <div
      onClick={() =>
        setMoreContent((prev) => {
          if (prev.includes(appName)) {
            return prev.filter((p) => p !== appName);
          }

          return [...prev, appName];
        })
      }
      className={s.appCardContainer}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 0.3fr',
          gap: '10px',
          marginTop: '5px',
        }}
      >
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={appName}
            onChange={(e) => setName(e.target.value)}
            className="bp4-input bp4-fill"
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
          {error && <small style={{ color: 'red' }}>{error}</small>}
        </div>

        <div>
          <label htmlFor="owner">Owner</label>
          <div
            className="bp4-select bp4-fill"
            onClick={(e) => e.stopPropagation()}
          >
            <OwnerSelect
              itemRenderer={ownerRenderer}
              onItemSelect={(item, e) => {
                e?.stopPropagation();
                setSelectedOwner(item);
              }}
              items={VALID_OWNERS}
              filterable={false}
              popoverProps={{ fill: true, minimal: true }}
            >
              <Button
                text={selectedOwner}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              />
            </OwnerSelect>
          </div>
        </div>

        <div>
          <label htmlFor="bypass">Bypass decoding</label>
          <div style={{ display: 'flex', alignItems: 'center', height: '75%' }}>
            <Switch
              id="bypass"
              checked={bypass}
              onChange={() => setBypass(!bypass)}
              large
            />
            <span>{bypass ? 'Enabled' : 'Disabled'}</span>
          </div>
        </div>
      </div>

      <div
        style={{
          width: '93%',
          paddingLeft: '25px',
          marginLeft: '10px',
          borderLeft: '1px solid black',
        }}
      >
        {appEndpoints?.length > 0 ? (
          appEndpoints.map((end, i) => {
            const isLast = appEndpoints.length === i + 1;

            if (moreContent.includes(appName)) {
              return (
                <Endpoint
                  key={end.id}
                  index={i}
                  endpoint={end}
                  showButtonIfLast={isLast}
                  handleAddEndpoint={handleAddEndpoint}
                  handleModifyAppEndpoint={handleModifyEndpoint}
                  handleConnectorDuplicates={handleConnectorDuplicates}
                  anyConnectorSelectedHandler={anyConnectorSelectedHandler}
                  handleDeleteEndpoint={handleDeleteEndpoint}
                />
              );
            }
            return null;
          })
        ) : (
          <div style={{ height: '30px', paddingTop: '9px' }}>
            <Button
              text="Add endpoint"
              style={{ height: '30px' }}
              intent={Intent.PRIMARY}
              onClick={() =>
                handleAddEndpoint({
                  endpoint: '',
                  when: { connector: Connectors.UNKNOWN },
                })
              }
            />
          </div>
        )}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <Icon
          icon={
            moreContent.includes(appName)
              ? IconNames.CHEVRON_UP
              : IconNames.CHEVRON_DOWN
          }
          color="gray"
        />
      </div>
    </div>
  );
};

export default AppCard;
