import { Button, Intent, MenuItem } from '@blueprintjs/core';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import s from './index.module.scss';
import { IMAGE_PATHS } from 'constants/imagePaths';
import type { ItemRenderer } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';
import { IconNames } from '@blueprintjs/icons';
import type {
  GatewayNetworkConfig,
  GatewayRadioConfig,
  Schema,
} from 'api/gateway/types';

interface IProps {
  graphic: string;
  title: string;
  networkProtocols: Schema[] | undefined;
  radioBands: string[];
  data: GatewayNetworkConfig[] | GatewayRadioConfig[];
  missionId: number | undefined;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
  setProtocolToEdit:
    | React.Dispatch<React.SetStateAction<GatewayNetworkConfig | undefined>>
    | undefined;
  setRadioToEdit:
    | React.Dispatch<React.SetStateAction<GatewayRadioConfig | undefined>>
    | undefined;
  setRadios:
    | React.Dispatch<React.SetStateAction<GatewayRadioConfig[]>>
    | undefined;
  setProtocols:
    | React.Dispatch<React.SetStateAction<GatewayNetworkConfig[]>>
    | undefined;
  deleteRadioSettingsByMission: (id: number, radio: string) => Promise<void>;
}

const ProtocolSelect = Select.ofType<Schema>();
const RadioSelect = Select.ofType<string>();

const LibraryHomeCard = ({
  networkProtocols,
  radioBands,
  graphic,
  title,
  data,
  missionId,
  setShownCards,
  setRadioToEdit,
  setProtocolToEdit,
  setProtocols,
  setRadios,
  deleteRadioSettingsByMission,
}: IProps) => {
  const [selectedProtocol, setSelectedProtocol] = useState<Schema | undefined>(
    networkProtocols?.[0]
  );
  const [selectedRadio, setSelectedRadio] = useState<string>(radioBands?.[0]);

  const protocolRenderer: ItemRenderer<Schema> = (
    protocol,
    { handleClick }
  ) => {
    const selected = protocol.name === selectedProtocol?.name;

    return (
      <MenuItem
        key={protocol.name}
        text={protocol.name}
        onClick={handleClick}
        active={selected}
        disabled={(data as unknown as GatewayNetworkConfig[]).some(
          (prot) => prot.protocol === protocol.name
        )}
      />
    );
  };

  const radioRenderer: ItemRenderer<string> = (radio, { handleClick }) => {
    const selected = radio === selectedRadio;

    return (
      <MenuItem
        key={radio}
        text={radio}
        onClick={handleClick}
        active={selected}
        disabled={(data as unknown as GatewayRadioConfig[]).some(
          (prot) => prot.radio === radio
        )}
      />
    );
  };

  const renderBottomContent = () => {
    if (networkProtocols) {
      return (
        <>
          <ProtocolSelect
            items={networkProtocols}
            onItemSelect={(item) => setSelectedProtocol(item)}
            itemRenderer={protocolRenderer}
            popoverProps={{ fill: true, minimal: true }}
            filterable={false}
            className="bp4-select bp4-fill"
          >
            <Button
              text={selectedProtocol?.name}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            />
          </ProtocolSelect>
          <Button
            text="Add"
            intent={Intent.PRIMARY}
            style={{ width: '150px' }}
            onClick={() => {
              if (!missionId) {
                return;
              }

              setProtocols?.((prev) => [
                ...prev,
                {
                  mission_id: missionId,
                  protocol: selectedProtocol?.name ?? 'n/a',
                  mission_topology: {},
                  protocol_config: {},
                },
              ]);
            }}
            disabled={(data as unknown as GatewayNetworkConfig[]).some(
              (prot) =>
                selectedProtocol && prot.protocol === selectedProtocol.name
            )}
          />
        </>
      );
    }

    return (
      <>
        <RadioSelect
          items={radioBands}
          onItemSelect={(item) => setSelectedRadio(item)}
          itemRenderer={radioRenderer}
          popoverProps={{ fill: true, minimal: true }}
          filterable={false}
          className="bp3-select bp3-fill"
        >
          <Button
            text={selectedRadio}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          />
        </RadioSelect>
        <Button
          text="Add"
          intent={Intent.PRIMARY}
          style={{ width: '150px' }}
          onClick={() => {
            if (!missionId) {
              return;
            }
            setRadios?.((prev) => [
              ...prev,
              {
                mission_id: missionId,
                radio: selectedRadio,
                transformers: { uplink: [], downlink: [] },
              },
            ]);
          }}
          disabled={
            selectedRadio === '' ||
            (data as GatewayRadioConfig[]).some(
              (radio) => radio.radio === selectedRadio
            )
          }
        />
      </>
    );
  };

  const renderList = () => {
    if (networkProtocols) {
      return (data as GatewayNetworkConfig[]).map((d) => (
        <div className={s.homeCardListItem} key={d.uid}>
          <span className={s.itemName}>{d.protocol}</span>
          <div>
            <span className={s.itemInfo}>
              {Object.keys(d.mission_topology).length} network nodes
            </span>
            <Button
              text="Edit"
              className={s.itemButton}
              onClick={() => {
                setProtocolToEdit?.(d);
                setShownCards(ShownCards.NETWORK_PROTOCOLS);
              }}
            />
          </div>
        </div>
      ));
    }
    return (data as GatewayRadioConfig[]).map((d) => (
      <div className={s.homeCardListItem} key={d.uid}>
        <span className={s.itemName}>{d.radio}</span>
        <div>
          <span className={s.itemInfo}>
            {Object.keys(d.transformers.uplink).length} uplink and{' '}
            {Object.keys(d.transformers.downlink).length} downlink transformers
          </span>
          <Button
            text="Edit"
            className={s.itemButton}
            onClick={() => {
              setRadioToEdit?.(d);
              setShownCards(ShownCards.RADIO_ENCODING);
            }}
          />

          <Button
            icon={IconNames.TRASH}
            intent={Intent.DANGER}
            style={{ marginLeft: '5px' }}
            onClick={async () => {
              if (!missionId) {
                return;
              }

              try {
                await deleteRadioSettingsByMission(missionId, d.radio);
                setRadios?.((prev) => prev.filter((p) => p.uid !== d.uid));
              } catch (error) {
                //
              }
            }}
          />
        </div>
      </div>
    ));
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '50%',
        minWidth: '900px',
        maxHeight: '350px',
      }}
    >
      <div
        className={s.libraryHomeCard}
        style={{
          marginBottom: '25px',
          zIndex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span className={s.homeCardTitle}>{title}</span>
        <div className={s.homeCardListContainer}>{renderList()}</div>
        <div className={s.homeCardBottomContentContainer}>
          {renderBottomContent()}
        </div>
      </div>
      <img
        onError={(e: ChangeEvent<HTMLImageElement>) => {
          e.target.src = IMAGE_PATHS.ICONS + graphic + '.svg';
          e.target.style.filter = 'invert(1)';
        }}
        src={IMAGE_PATHS.ILLUSTRATIONS + graphic + '.svg'}
        className={s.homeCardImage}
      />
    </div>
  );
};

export default LibraryHomeCard;
