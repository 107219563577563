import classNames from 'classnames';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { useState } from 'react';
import placeholder from 'images/datacosmos/img-placeholder.svg';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button } from 'opencosmos-ui';

interface IProps {
  index: IExpressionDetails;
  isIndexSelected: boolean;
  toggleSelectedIndex: (index: IExpressionDetails) => void;
  deleteHandler?: (index: IExpressionDetails) => void;
}

const IndexCard = ({
  index,
  isIndexSelected,
  toggleSelectedIndex,
  deleteHandler,
}: IProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { translateWithExplicitFallback } = useLocalisation();

  return (
    <div
      className={classNames(
        'flex flex-col mb-px hover:bg-item-selected dark:hover:bg-item-dark-selected p-2 cursor-pointer group w-full',
        {
          'bg-item-selected dark:bg-item-dark-selected': isIndexSelected,
        }
      )}
      onClick={() => toggleSelectedIndex(index)}
    >
      <div className="flex gap-2 items-center">
        <img
          src={index.thumbnail ? index.thumbnail : placeholder}
          className="object-cover w-24 h-24"
        />

        <div className="flex flex-col gap-2 max-w-[250px] w-[250px]">
          <span className="text-sm group-hover:text-accent-900 dark:group-hover:text-item-dark-contrast">
            {translateWithExplicitFallback(
              `datacosmos.layers.bandAlgebra.predefined.index.${index.id!}.title` as unknown as TemplateStringsArray,
              index.uiName
            )}
          </span>
          <span className="text-sm">{index.expression}</span>
        </div>

        <div className="flex gap-2">
          <Button
            icon="Info"
            size={'base'}
            onPress={() => {
              setIsExpanded(!isExpanded);
            }}
            isMinimal
            isTransparent
          />

          {deleteHandler && (
            <Button
              icon="Trash"
              onPress={() => {
                deleteHandler(index);
              }}
              size={'base'}
              isMinimal
              isTransparent
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="mt-2">
          <p className="text-sm">
            {index.description ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: translateWithExplicitFallback(
                    `datacosmos.layers.bandAlgebra.predefined.index.${index.id!}.description` as unknown as TemplateStringsArray,
                    index.description
                  ),
                }}
              ></span>
            ) : (
              <>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consectetur eos alias tenetur deserunt dolorum atque natus magni
                exercitationem voluptatum numquam. Culpa ratione iste voluptatem
                fugit, beatae vero delectus sint aliquid.
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default IndexCard;
