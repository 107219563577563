import React, { useState } from 'react';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import { Button, ButtonGroup, Classes, Dialog, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { format, useNewTimer } from 'components/Timer';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';

import GSEphemerisInfo from './GSEphemerisInfo';
import GSEphemerisHistory from './GSEphemerisHistory';
import type { Ephemeris, EphemerisParams } from 'api/ephemeris/types';
import s from '../../index.module.scss';
import { useMission } from 'services/Missions';
import GSAddTLEModal from './GSAddTLEModal';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  ephemeris: Ephemeris;
  ephemerisList: Ephemeris[];
  getEphemeris: (params: EphemerisParams) => void;
  saveTLE: (line1: string, line2: string) => void | Promise<void>;
  isSavingTLE: boolean;
}

const GSCurrentEphemeris = (props: IProps) => {
  const { currentMissionId } = useMission();

  const [isViewExpanded, setIsViewExpanded] = useState<boolean>(false);
  const [isHistoryOpen, setHistoryOpen] = useState<boolean>(false);
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
  const [isAddEphemerisOpen, setIsAddEphemerisOpen] = useState<boolean>(false);

  const timer = useNewTimer(getOr(moment.utc(), 'ephemeris.createdAt', props));

  const { hasPermission: isAllowedToReadEphemeris } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:ephemeris:read',
      id: currentMissionId,
    },
  });

  const { hasPermission: isAllowedToWriteEphemeris } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:ephemeris:write',
      id: currentMissionId,
    },
  });

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={s.gsEphemerisContainer}
        onClick={() => setIsViewExpanded(!isViewExpanded)}
        onKeyDown={(ev) =>
          ev.keyCode === 13 && setIsViewExpanded(!isViewExpanded)
        }
      >
        <div className={s.tagHeader}>
          <span className={s.gsEphemerisTimer}>
            Ephemeris last updated {format(timer)}
          </span>
          {isViewExpanded && (
            <ButtonGroup className={s.ephemerisButtonGroup}>
              <Button
                small
                text="History"
                onClick={preventDefaultPropagation(setHistoryOpen)(true)}
                disabled={!isAllowedToReadEphemeris}
              />
              <Button
                small
                text="Info"
                onClick={preventDefaultPropagation(setInfoOpen)(true)}
                disabled={!isAllowedToReadEphemeris}
              />
              <Button
                small
                text="Add"
                onClick={preventDefaultPropagation(setIsAddEphemerisOpen)(true)}
                disabled={!isAllowedToWriteEphemeris}
              />
            </ButtonGroup>
          )}
          <Icon
            className={s.infoIcon}
            icon={isViewExpanded ? IconNames.CROSS : IconNames.WRENCH}
          />
        </div>
      </div>
      <Dialog
        style={{ width: 700, maxHeight: 600 }}
        title="History"
        isOpen={isHistoryOpen}
        usePortal={true}
        canOutsideClickClose={true}
        onClose={() => setHistoryOpen(false)}
      >
        <GSEphemerisHistory
          className={`${Classes.DIALOG_BODY} ${s.ephemerisHistoryDialogBody}`}
          ephemerisList={props.ephemerisList}
          getEphemeris={props.getEphemeris}
        />
      </Dialog>
      <Dialog
        title="Info"
        isOpen={isInfoOpen}
        usePortal={true}
        canOutsideClickClose={true}
        onClose={() => setInfoOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <GSEphemerisInfo ephemeris={props.ephemeris} />
        </div>
      </Dialog>

      <GSAddTLEModal
        isOpen={isAddEphemerisOpen}
        setIsOpen={setIsAddEphemerisOpen}
        saveTLE={props.saveTLE}
        isSavingTLE={props.isSavingTLE}
      />
    </>
  );
};

export default GSCurrentEphemeris;
