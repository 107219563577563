import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isString } from 'lodash/fp';
import { Classes, ControlGroup, HTMLSelect, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import base64Utils, {
  BASE64_MODES,
  findBase64Transformer,
  UTF8,
  validate,
} from '../../../../../../../utils/common/base64Utils';
import InputFileButton from '../../../../../../../components/buttons/InputFileButton';
import { loadAndConvertToBase64 } from '../../../../../../../utils/fileUpload';
import { showErrorMessage } from '../../../../../../../utils/common/CommonUtils';
import s from '../index.module.scss';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  fullPath: string;
  value: string;
  disabled: boolean;
  path: string;
  showInfoMark?: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  validateChange: Function;
  setValidationParams: Function;
  setTooltipOpen: (open: boolean) => void;
}

interface ICustomEvent {
  target: {
    id: string;
    name: string;
    value: string;
  };
}

const MAX_FILE_SIZE = 5000;

function generateDisplayValue(
  base64Value: string,
  mode: BASE64_MODES
): string | undefined {
  if (mode === BASE64_MODES.BASE64) {
    return base64Value;
  }

  const binaryTransformer = findBase64Transformer(
    BASE64_MODES.BASE64,
    mode
  )?.func;

  if (typeof binaryTransformer === 'undefined') {
    return undefined;
  }

  const value = isString(base64Value) ? base64Value : '';

  return binaryTransformer(value);
}

/**
 * calculateNewCursorPosition returns the cursor index in a formatted string
 * based on the cursor index in the unformatted string
 */
export function calculateNewCursorPosition(
  prevCursor: number,
  unformattedValue: string,
  formattedValue: string
): number {
  // NOTE: If you are trying to understand this function, check the unit tests!

  // If the cursors was at the end, keep it there
  if (prevCursor === unformattedValue.length) {
    return formattedValue.length;
  }

  // If the cursors was at the start, keep it there
  if (prevCursor === 0) {
    return 0;
  }

  // Keeps track of the characters in the unformatted string that have been
  // accounted for in the formatted string
  let charactersAccountedFor = 0;

  // Iterate over the formatted string until we find the character where the
  // cursor was before the formatting. To do so we will try to find each of the
  // characters in the formatted string in the unformatted string.
  //
  // For a given character search, if the end of the unformatted string is
  // reached and the character is not found, we assume that the current character
  // in the formatted string is added for formatting purposes (like a space
  // between "aa" and "bb" in hex format); hence, we simply skip it by returning
  // false (ergo, not found).
  //
  // This works under the assumption that the character where the cursor was
  // in the unformatted string will be in the formatted string.
  const foundIndex = [...formattedValue].findIndex(
    (formattedCharacterToFind) => {
      let unformattedStringScanningIndex = charactersAccountedFor;

      // We can try to match the characters in the unformatted string until the
      // place where the cursor was.
      while (unformattedStringScanningIndex < prevCursor) {
        if (
          formattedCharacterToFind ===
          unformattedValue[unformattedStringScanningIndex]
        ) {
          charactersAccountedFor = unformattedStringScanningIndex + 1;
          if (charactersAccountedFor === prevCursor) {
            return true;
          }
          return false;
        }

        unformattedStringScanningIndex += 1;
      }

      return false;
    }
  );

  // Add one to make the cursor be after the character, not in front of it.
  return foundIndex + 1;
}

function useRunAfterUpdate() {
  const afterPaintRef = useRef<Function | null>(null);
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  return useCallback((fn: Function) => (afterPaintRef.current = fn), []);
}

const CommandStringBase64Input = (props: IProps) => {
  const {
    inputRef,
    value,
    onChange,
    fullPath,
    disabled,
    validateChange,
    handleBlur,
    handleFocus,
    path,
    showInfoMark,
    className,
    setValidationParams,
  } = props;

  // the displayValue is the value that is shown in the input field. The format
  // in which the display value is shown depends on the selected mode.
  const [displayValue, setDisplayValue] = useState('');
  const [mode, setMode] = useState<BASE64_MODES>(BASE64_MODES.BASE64);
  const isDisplayValueValid = useMemo(
    () => validate[mode](displayValue),
    [displayValue, mode]
  );
  const runAfterUpdate = useRunAfterUpdate();
  const lastCursorWhenOnChange = useRef(-1);

  useEffect(() => {
    const newDisplayValue = generateDisplayValue(value, mode) || '';
    setDisplayValue(newDisplayValue);

    setValidationParams({
      stringBase64: {
        mode: BASE64_MODES.BASE64,
      },
    });

    // Keep the cursor position after updating the value
    runAfterUpdate(() => {
      if (lastCursorWhenOnChange.current === -1) {
        return;
      }
      const newCursorPositon = calculateNewCursorPosition(
        lastCursorWhenOnChange.current,
        displayValue,
        newDisplayValue
      );

      if (inputRef === undefined || inputRef.current === null) {
        return;
      }

      inputRef.current.selectionStart = newCursorPositon;
      inputRef.current.selectionEnd = newCursorPositon;
      lastCursorWhenOnChange.current = -1;
    });
    // displayValue should not be on the array below
  }, [inputRef, mode, runAfterUpdate, setValidationParams, value]);

  const checkIfFileSizeIsExceeded = (size: number) => {
    if (size > MAX_FILE_SIZE) {
      showErrorMessage(
        `File is too large. File should be less then ${
          MAX_FILE_SIZE / 1000
        } KB.`
      );
      return true;
    }
    return false;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | ICustomEvent,
    eventMode?: BASE64_MODES
  ) => {
    const newValue = e.target.value;

    const size = new Blob([newValue]).size;
    if (checkIfFileSizeIsExceeded(size)) {
      return;
    }

    let base64Value: string = newValue;
    const newValueMode: BASE64_MODES = eventMode ?? mode;

    setDisplayValue(newValue);

    if (!validate[mode](newValue) || newValue.endsWith(' ')) {
      // The current value is not valid (or the last value is a space), it
      // probably means the user has not finished typing the value. We don't
      // want to update the value to the parent until the user has finished
      // typing a valid input.
      return;
    }

    if (newValue === '') {
      base64Value = '';
    } else if (BASE64_MODES.BASE64 !== newValueMode) {
      const newTransformer = findBase64Transformer(mode, BASE64_MODES.BASE64);
      if (newTransformer === undefined) {
        throw new Error(
          'Could not find a binary transformer for mode "' + mode + '"'
        );
      }

      const newBase64Value = newTransformer.func(newValue);
      if (newBase64Value === undefined) {
        throw new Error(
          'Base64 value could not be calculated from value "' +
            newValue +
            '" in mode "' +
            mode +
            '"'
        );
      }

      base64Value = newBase64Value;
    }

    if (inputRef === undefined || inputRef.current === null) {
      throw new Error(
        'Binary input does not have a reference to the input element'
      );
    }

    // Keep the cursor position after updating the value
    if (
      inputRef.current.selectionStart !== newValue.length &&
      typeof inputRef.current.selectionStart === 'number'
    ) {
      lastCursorWhenOnChange.current = inputRef.current.selectionStart;
    }

    onChange(base64Value, fullPath);
    e.target.value = base64Value;
    validateChange(e);
  };

  const handleModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMode = e.target.value;

    setMode(newMode as BASE64_MODES);
  };

  const showUtf8 = base64Utils.base64toUTF8(value) ?? !value;

  const handlePutFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      showErrorMessage(
        `Could not find file. Please try again or contact support.`
      );
      return;
    }

    const file = e.target.files[0];

    if (checkIfFileSizeIsExceeded(file.size)) {
      return;
    }

    void loadAndConvertToBase64(file).then((base64) => {
      const customEvent: ICustomEvent = {
        target: {
          id: path,
          name: path,
          value: base64,
        },
      };

      handleInputChange(customEvent, BASE64_MODES.BASE64);
    });
  };

  return (
    <ControlGroup className={s.stringBase64Container} fill>
      <HTMLSelect
        className={s.base64TypeSelect}
        disabled={disabled || !isDisplayValueValid}
        value={mode}
        onChange={handleModeChange}
      >
        {Object.values(BASE64_MODES).map((it) => (
          <option key={it}>{it}</option>
        ))}
        {showUtf8 && <option>{UTF8}</option>}
      </HTMLSelect>
      <input
        ref={inputRef}
        className={className}
        id={path || 'input_str_base64'}
        name={path || 'input_str_base64'}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseOut={!disabled ? handleBlur : undefined}
        onMouseOver={!disabled ? handleFocus : undefined}
        value={displayValue}
        onChange={handleInputChange}
      />
      {showInfoMark && (
        <Icon
          className={[s.iconWithInput, s.iconWithInputBase64].join(' ')}
          icon={IconNames.INFO_SIGN}
        />
      )}
      <InputFileButton
        disabled={disabled}
        className={[Classes.BUTTON, s.inputFileWrapper].join(' ')}
        id="base64-file-upload"
        icon={IconNames.DOCUMENT}
        onBlur={handleBlur}
        onMouseOut={!disabled ? handleBlur : undefined}
        onFocus={handleFocus}
        onMouseOver={!disabled ? handleFocus : undefined}
        onChange={handlePutFile}
      />
    </ControlGroup>
  );
};

export default CommandStringBase64Input;
