import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import {
  Button,
  Label,
  PopoverProps,
  Select,
  SelectProps,
  ValidationResult,
  Text,
  FieldError,
  Popover,
  ListBox,
  ListBoxItem,
} from "react-aria-components";
import { Icon } from "../../icons/Icon";
import { tv } from "tailwind-variants";

const select = tv({
  base: "flex items-center gap-1 text-start bg-item dark:bg-item-dark dark:text-item-dark-contrast border dark:border-item-dark-contrast border-item-contrast",
  variants: {
    fill: {
      true: "w-full",
      false: "",
    },
  },
});

interface MySelectProps<T extends object>
  extends Omit<
    SelectProps<T>,
    "children" | "items" | "onSelectionChange" | "defaultSelectedKey"
  > {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items: { id: string; value: string }[];
  fill?: boolean;
  popoverProps?: PopoverProps;
  onSelectionChange?: (item: { id: string; value: string }) => void;
  defaultSelectedKeys?: string[];
  onRemove?: (item: { id: string; value: string }) => void;
}

const MultiSelect = <T extends Object>({
  label,
  description,
  errorMessage,
  items,
  fill,
  popoverProps,
  defaultSelectedKeys,
  ...props
}: MySelectProps<T>) => {
  const selRef = useRef<HTMLDivElement>(null);
  const [selectedItems, setSelectedItems] = React.useState<
    { id: string; value: string }[]
  >(items.filter((i) => defaultSelectedKeys?.some((k) => i.id === k)));
  const [isOpen, setIsOpen] = React.useState(false);

  const itemsWithSelectStatus = useMemo(() => {
    return items.map((item) => ({
      ...item,
      selected: selectedItems.some((i) => i.id === item.id),
    }));
  }, [items, selectedItems]);

  const addSelection = (key: string) => {
    const foundItem = items.find((item) => item.id === key);

    if (!foundItem) {
      return;
    }
    setSelectedItems((prev) => [...prev, foundItem]);
  };

  const removeSelection = (item: { id: string; value: string }) => {
    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
    props.onRemove?.(item);
  };

  const toggleSelection = (item: { id: string; value: string }) => {
    if (selectedItems.some((i) => i.id === item.id)) {
      return removeSelection(item);
    }

    return addSelection(item.id);
  };
  return (
    <Select
      {...props}
      ref={selRef}
      isOpen={isOpen}
      onSelectionChange={(key) => {
        const foundItem = items.find((item) => item.id === key);

        if (!foundItem) {
          return;
        }

        toggleSelection(foundItem);
        props.onSelectionChange?.(foundItem);
      }}
      className={(params) => {
        let p = {
          ...params,
          fill,
        };
        return select({
          ...p,
          class:
            typeof props.className === "function"
              ? props.className(p)
              : props.className,
        });
      }}
    >
      <Label className="whitespace-nowrap">{label}</Label>
      <Button
        className={classNames(
          "flex gap-1 items-center text-start",
          "flex items-center gap-1  bg-item dark:bg-item-dark dark:text-item-dark-contrast border dark:border-item-dark-contrast border-item-contrast",
          {
            "w-full": fill,
          }
        )}
        onPress={() => setIsOpen(!isOpen)}
      >
        <div
          className={classNames(
            "flex gap-1 flex-wrap min-w-[165px] h-8 max-h-8",
            "p-1 hover:bg-item-hover dark:hover:bg-item-dark-hover focus-visible:bg-item-hover dark:focus-visible:bg-item-dark-hover focus-visible:outline-2 focus-visible:outline-neutral-600 focus-visible:outline",
            "overflow-x-auto scrollbar-invisible",
            {
              "w-full": fill,
            }
          )}
        >
          {selectedItems.length > 0 ? (
            selectedItems.map((item) => (
              <div className="text-xs flex gap-1 items-center rounded-md border bg-item-dark text-item-dark-contrast dark:bg-item-dark-contrast dark:text-item-dark border-item-contrast dark:border-item-dark-contrast p-[2px]">
                <span>{item.value}</span>
                {props.onRemove && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeSelection(item);
                    }}
                    className="flex items-center group hover:stroke-accent"
                  >
                    <Icon icon="cross" size={12} />
                  </button>
                )}
              </div>
            ))
          ) : (
            <span>Select</span>
          )}
        </div>
        <Icon icon="ChevronDown" />
      </Button>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover
        className={
          "bg-item dark:text-item-dark-contrast cursor-pointer dark:bg-item-dark max-h-96 overflow-auto h-96"
        }
        style={{ width: selRef.current?.clientWidth }}
        {...popoverProps}
        isNonModal={true}
        shouldUpdatePosition={true}
      >
        <ListBox
          selectionMode="multiple"
          selectedKeys={selectedItems.map((si) => si.id)}
          items={itemsWithSelectStatus}
        >
          {itemsWithSelectStatus.map((item) => (
            <ListBoxItem
              className={classNames({
                "bg-item-selected dark:bg-item-dark-selected": item.selected,
              })}
              id={item.id}
            >
              {item.value}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
};

export default MultiSelect;
