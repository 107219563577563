import { getWorkflowStatuses, submitWorkflow } from 'api/pdgs/service';
import type { PdgsInput, PdgsWorkflow } from 'api/pdgs/types';
import { useQuery } from 'api/useQuery';
import { DATE_FORMAT, ISO_FORMAT } from 'constants/datetime';
import { PROJECT_ID_FOR_PDGS_LINK } from 'env';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useMission } from 'services/Missions';

type WorkflowByDate = {
  [date: string]: PdgsWorkflow[];
};

type DateRangeFilter = {
  start_time: Date | null | undefined;
  end_time: Date | null | undefined;
};

export const usePdgsData = () => {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilter>();
  const [selectedWorkflow, setSelectedWorkflow] = useState<PdgsWorkflow>();

  const [isResubmitting, setIsResubmitting] = useState<boolean>(false);

  const { currentMissionId } = useMission();

  const history = useHistory();
  const params = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );

  const {
    data: pdgsWorkflows,
    loading,
    errors,
  } = useQuery(getWorkflowStatuses, {
    params: {
      end_time: dateRangeFilter?.end_time
        ? moment(dateRangeFilter.end_time).format(ISO_FORMAT)
        : undefined,
      start_time: dateRangeFilter?.start_time
        ? moment(dateRangeFilter.start_time).format(ISO_FORMAT)
        : undefined,
      mission_id: currentMissionId!,
    },
    initialData: [],
    skip: !currentMissionId,
  });

  const datacosmosItemUrl = useMemo(() => {
    const stacItemId = selectedWorkflow?.outputs.find(
      (o) => o.field === 'outputs-stac-item-id'
    )?.value;
    const stacCollectionId = selectedWorkflow?.outputs.find(
      (o) => o.field === 'outputs-stac-item-collection-id'
    )?.value;

    if (!stacItemId || !stacCollectionId) {
      return undefined;
    }

    const url = `${window.location.protocol}//${
      window.location.host
    }/data/project/${PROJECT_ID_FOR_PDGS_LINK}/catalog/?view-item=${encodeURIComponent(
      `${stacCollectionId}/${stacItemId}`
    )}`;

    return url;
  }, [selectedWorkflow?.outputs]);

  const handleUrlDates = useCallback(() => {
    if (!dateRangeFilter) {
      return;
    }

    const queryParams = Object.entries(dateRangeFilter)
      .filter(([_, v]) => v !== undefined && v !== null)
      .map(([k, v]) => {
        return `${k}=${moment(v!).format(ISO_FORMAT)}`;
      })
      .join('&');

    history.replace({
      search: queryParams,
    });
  }, [dateRangeFilter, history]);

  const parseUrlDates = useCallback(() => {
    const start = params.get('start_time');
    const end = params.get('end_time');

    setDateRangeFilter({
      start_time: start ? moment(start, ISO_FORMAT).toDate() : undefined,
      end_time: end ? moment(end, ISO_FORMAT).toDate() : undefined,
    });
  }, [params]);

  const handleWorkflowResubmit = async (name: string, inputs: PdgsInput[]) => {
    if (!currentMissionId) {
      return;
    }

    const inputsObj = inputs.reduce((acc, i) => {
      return {
        ...acc,
        [i.field]: i.value,
      };
    }, {} as PdgsInput);

    setIsResubmitting(true);

    await submitWorkflow({
      body: {
        labels: {
          'app.opencosmos.com/mission_id': currentMissionId.toString(),
        },
        name: name,
        inputs: inputsObj,
      },
    });

    setIsResubmitting(false);
  };

  const workflowsGroupedByDate = useMemo(() => {
    let correctedWorkflows: PdgsWorkflow[] = [];
    if (pdgsWorkflows !== null) {
      correctedWorkflows = pdgsWorkflows;
    }
    //TODO: Replace mock with actual implementation when backend gets fixed
    return correctedWorkflows.reduce((acc, w) => {
      const formattedStartDate = moment(w.started_at).format(DATE_FORMAT);
      // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
      if (!acc[formattedStartDate]) {
        acc = { ...acc, [formattedStartDate]: [w] };
      } else {
        acc = {
          ...acc,
          [formattedStartDate]: [...acc[formattedStartDate], w],
        };
      }

      return acc;
    }, {} as WorkflowByDate);
  }, [pdgsWorkflows]);

  useEffect(() => {
    parseUrlDates();
  }, [parseUrlDates]);

  useEffect(() => {
    handleUrlDates();
  }, [handleUrlDates]);

  return {
    workflowsGroupedByDate,
    dateRangeFilter,
    setDateRangeFilter,
    setSelectedWorkflow,
    selectedWorkflow,
    loading,
    errors,
    handleWorkflowResubmit,
    isResubmitting,
    datacosmosItemUrl,
  };
};
