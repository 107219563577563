import { Intent } from '@blueprintjs/core';
import TagHeader from 'components/common/TagHeader';
import TelemetryFilter from 'pages/ops/RTI/Operate/components/Telemetries/TelemetryFilter';
import type { Telemetry } from 'api/telemetry/types';
import s from './index.module.scss';

interface IProps {
  title: string;
  telemetryDefinitions: Telemetry[];
  handleFilterChange: (newFilter: string) => void;
}

const LibraryTelemetryHeader = (props: IProps) => {
  return (
    <div className={s.variablesTitle}>
      <TagHeader
        intent={Intent.PRIMARY}
        title={
          <div className={s.libraryHeader}>
            <div className={s.filterContainer}>
              <span>{props.title}</span>
              <TelemetryFilter
                telemetries={props.telemetryDefinitions}
                handleFilterChange={props.handleFilterChange}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default LibraryTelemetryHeader;
