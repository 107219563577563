import classNames from "classnames";
import {
  Label,
  PopoverProps,
  Select,
  SelectValue,
  type SelectProps,
  type ValidationResult,
} from "react-aria-components";
import Button from "../Button/Button";
import Popover from "../Popover/Popover";
import ListBox from "../List/ListBox";
import Icon from "../../icons/Icon";
import { useEffect, useRef, useState } from "react";
import { zIndexValues } from "../../../constants";

interface MySelectProps<T extends object>
  extends Omit<SelectProps<T>, "children"> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  fill?: boolean;
  testid?: string;
  selectedItemProps?: {
    className?: string;
    intent?: "none" | "success" | "warning";
  };
  popoverProps?: PopoverProps;
}

const MySelect = <T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  popoverProps,
  selectedItemProps,
  ...props
}: MySelectProps<T>) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [popoverWidth, setPopoverWidth] = useState<number>();
  useEffect(() => {
    setPopoverWidth(ref.current?.clientWidth);
  }, []);
  return (
    <Select
      {...props}
      className={classNames({ "w-full": props.fill })}
      aria-label="select"
    >
      {label && <Label className="block mb-1">{label}</Label>}
      <Button
        size="sm"
        className={classNames(
          "h-[2.3em] justify-start min-w-[110px]",
          selectedItemProps?.className,
          {
            "w-full": props.fill,
            "bg-warning dark:bg-warning":
              selectedItemProps?.intent === "warning",
            "bg-success dark:bg-success":
              selectedItemProps?.intent === "success",
          }
        )}
        ref={ref}
        data-testid={props["testid"]}
        isMinimal
      >
        <span aria-hidden="true">
          <Icon icon="ChevronDown" className="mt-1" />
        </span>
        <SelectValue />
      </Button>
      <Popover
        {...popoverProps}
        triggerRef={ref}
        className={`!z-[${zIndexValues.popover}] bg-neutral-300 dark:bg-neutral-850 text-black dark:text-white border border-neutral-400 shadow-lg`}
        maxHeight={250}
        style={{ minWidth: popoverWidth }}
      >
        <ListBox items={items} className={"max-h-[250px]"}>
          {children}
        </ListBox>
      </Popover>
    </Select>
  );
};

export default MySelect;
