import React from 'react';
import { Classes } from '@blueprintjs/core';
import LibraryAlertsRow from './LibraryAlertsRow';
import s from './index.module.scss';
import { useAlerts } from '../../../../../services/api/telemetry/AlertsProvider';
import type { AlertRule } from 'api/alerts/types';

interface IProps {
  list: AlertRule[] | undefined;
  showAlertForRuleDeletion: (id: string) => void;
}

const LibraryAlertsTable = ({ list, showAlertForRuleDeletion }: IProps) => {
  const { editAlertRule, currentTelemetryHighlighted } = useAlerts();

  const filteredAlertRuleList = currentTelemetryHighlighted.length
    ? list?.filter((rule) =>
        currentTelemetryHighlighted.find((highlight) =>
          rule.expr?.includes(highlight)
        )
      )
    : list;

  return (
    <table className={[Classes.HTML_TABLE, s.tableContainer].join(' ')}>
      <thead>
        <tr>
          <th>Subsystem</th>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Expression</th>
          <th>Severity</th>
          <th>Team</th>
        </tr>
      </thead>
      <tbody>
        {filteredAlertRuleList?.map((alert) => (
          <LibraryAlertsRow
            key={alert.id}
            alert={alert}
            editHandler={() => editAlertRule(alert.id)}
            deleteHandler={() => showAlertForRuleDeletion(alert.id)}
          />
        ))}
      </tbody>
    </table>
  );
};

export default LibraryAlertsTable;
