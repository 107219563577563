import type { CovarianceMatrixData } from 'api/sampling/service';
import { Dialog } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  covarianceMatrix: CovarianceMatrixData | undefined;
}

const CovarianceModal = ({ isOpen, setIsOpen, covarianceMatrix }: Props) => {
  const { translate } = useLocalisation();
  const noValuesAvailable = !covarianceMatrix?.covariance_matrix?.value?.length;
  const noValuesAvailableMessage = translate(
    'datacosmos.catalogAndItems.covarianceMatrixModal.noValuesAvailableMessage'
  );

  const matrix = covarianceMatrix?.covariance_matrix.value ?? [];

  const variables =
    covarianceMatrix?.covariance_matrix.metadata.variables ?? [];
  const formattedVariablesString =
    variables.slice(0, -1).join(', ') + ' & ' + variables.slice(-1);

  return (
    <Dialog
      isOpen={isOpen}
      buttons={[]}
      onClose={() => {
        setIsOpen(false);
      }}
      cancelButtonText={translate('datacosmos.buttons.close')}
      showButtonsInFooter
      title={translate(
        'datacosmos.catalogAndItems.covarianceMatrixModal.title'
      )}
    >
      {noValuesAvailable ? (
        <div>{noValuesAvailableMessage}</div>
      ) : (
        <div>
          <h2>
            {translate(
              'datacosmos.catalogAndItems.covarianceMatrixModal.bandsTitle',
              {
                allBands: formattedVariablesString,
              }
            )}
          </h2>

          <table className="my-4 table-fixed text-center">
            <thead className="sticky -top-4 color-surface">
              <tr>
                <th></th>
                {variables.map((label) => (
                  <th key={label} className="text-center">
                    {label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {matrix.map((row, rowIndex) => (
                <tr key={row[0]}>
                  <td className="p-1 font-bold sticky -left-4 color-surface">
                    {variables[rowIndex]}
                  </td>
                  {row.map((value) => (
                    <td key={value} className="p-1">
                      {value.toFixed(2)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Dialog>
  );
};

export default CovarianceModal;
