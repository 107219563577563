import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo, useState } from 'react';
import type { ICommandPileItem } from '../../../../../../services/api/ops/realtimeTerminalSession';
import useDragAndDropSorting from '../../../../../../utils/hooks/useDragAndDropSorting';
import s from './index.module.scss';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import type { JsonSchema } from 'api/telecommands/types';

export interface IProps {
  className?: string;
  pileItem: ICommandPileItem;
  handleItemClick: (
    item: ICommandPileItem,
    addToMultipleItemDrag: boolean
  ) => void;
  buttonsList: React.ReactNode;
  headButtonsList?: React.ReactNode;
  commandSchema: JsonSchema;
  hoverPileItem: (dragIndex: number, hoverIndex: number) => void;
  dropPileItem?: (dragIndex: number, hoverIndex: number) => void;
  itemIndex: number;
  cardView?: boolean;
  shiftClickInitialIndex: number | null;
  setShiftClickInitialIndex: Dispatch<SetStateAction<number | null>>;
  handleShiftSelect: (initialIndex: number, endIndex: number) => void;
}

const CommandPileItem = (props: IProps) => {
  const [isSelectedCommandClicked, setIsSelectedCommandClicked] =
    useState<boolean>(false);
  const operateResult = useOperate();
  const {
    state: { selectedPileItem, selectedMultiplePileItems },
  } = operateResult || { state: {} };
  const { opacity, dndSortingRef } = useDragAndDropSorting(
    props.itemIndex,
    props.hoverPileItem,
    props.dropPileItem
  );

  const displayContent = useMemo(() => {
    const definitions = props.commandSchema.definitions;
    const definition = definitions
      ? definitions[props.pileItem.command]
      : undefined;
    const properties = definition?.properties;
    const payload = props.pileItem.payload as
      | { [key: string]: string | { type: string }[] }
      | undefined;

    const content: { key: string; title: string; value: string }[] = [];

    if (payload) {
      Object.keys(payload).forEach((key) => {
        let value = '';

        if (typeof payload[key] === 'string') {
          value = payload[key] as string;
        } else if (Array.isArray(payload[key])) {
          value = (payload[key] as { type?: string }[]).reduce(
            (acc, item) => (item?.type ? `${acc} ${item.type}` : acc),
            ''
          );
        }

        content.push({
          key,
          title: properties?.[key]?.title ?? key,
          value,
        });
      });
    }

    return content;
  }, [props.commandSchema, props.pileItem.command, props.pileItem.payload]);

  const handleListViewOnMouseDownMultipleDrag = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const shouldDragMultiple = e.ctrlKey || e.metaKey;
    if (selectedPileItem === props.pileItem) {
      setIsSelectedCommandClicked(true);
      return;
    }
    if (selectedMultiplePileItems?.includes(props.pileItem)) {
      setIsSelectedCommandClicked(true);
      return;
    }
    props.handleItemClick(props.pileItem, shouldDragMultiple);
    setIsSelectedCommandClicked(false);
  };

  const handleListViewOnClickMultipleDrag = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const shouldDragMultiple = e.ctrlKey || e.metaKey;
    if (selectedPileItem) {
      if (selectedPileItem !== props.pileItem) {
        props.handleItemClick(props.pileItem, shouldDragMultiple);
        return;
      }
      if (isSelectedCommandClicked) {
        props.handleItemClick(props.pileItem, shouldDragMultiple);
        return;
      }
    }
    if (selectedMultiplePileItems?.length) {
      if (!selectedMultiplePileItems?.includes(props.pileItem)) {
        props.handleItemClick(props.pileItem, shouldDragMultiple);
        return;
      }
      if (isSelectedCommandClicked) {
        props.handleItemClick(props.pileItem, shouldDragMultiple);
        return;
      }
    }
  };

  /**
   * handleListViewOnClickShiftSelect checks if shift key is pressed and
   * according to the set initial index, it will select all the commands
   * between the initial index and the current index.
   */
  const handleListViewOnClickShiftSelect = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const shouldShiftSelect =
      e.shiftKey && selectedPileItem && props.shiftClickInitialIndex !== null;

    props.setShiftClickInitialIndex(props.itemIndex);

    if (shouldShiftSelect) {
      props.handleShiftSelect(props.shiftClickInitialIndex!, props.itemIndex);
      props.setShiftClickInitialIndex(null);
    }
  };

  if (props.cardView) {
    return (
      <div
        role="presentation"
        style={{ opacity }}
        className={[props.className, s.commandPileItemCard].join(' ')}
        onClick={(e) => {
          const shouldDragMultiple = e.ctrlKey || e.metaKey;
          props.handleItemClick(props.pileItem, shouldDragMultiple);
        }}
        ref={dndSortingRef}
      >
        <span className="pb-1">
          {props.pileItem.customName
            ? props.pileItem.customName
            : props.pileItem.command}
        </span>
        <div className={s.buttonList}>
          {props.headButtonsList}
          {props.buttonsList}
        </div>
      </div>
    );
  }

  return (
    <div
      id={`command-pile-item-${props.pileItem.id ?? ''}`}
      data-testid="command-pile-item"
      role="presentation"
      style={{ opacity }}
      ref={dndSortingRef}
      className={[props.className, s.commandPileItem].join(' ')}
      onMouseDown={(e) => {
        handleListViewOnMouseDownMultipleDrag(e);
      }}
      onClick={(e) => {
        handleListViewOnClickMultipleDrag(e);
        handleListViewOnClickShiftSelect(e);
      }}
      key={props.pileItem.id}
    >
      <div>{props.headButtonsList}</div>
      <span className="whitespace-no-wrap overflow-hidden items-center flex font-extrabold text-ellipsis mr-2">
        {props.pileItem.command}
      </span>
      <div className={s.commandPileContent}>
        {props.pileItem.customName ? (
          <span className="font-light">{props.pileItem.customName}</span>
        ) : (
          <div className="flex">
            {displayContent.map((item) => (
              <div
                key={item.key}
                className="border-1 border-[#dddfe5] font-light flex ml-1 flex-col rounded-sm text-xs text-center"
              >
                <span className="font-bold bg-[#dddfe5] px-2">
                  {item.title}
                </span>
                <span className="font-light px-2">{item.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>{props.buttonsList}</div>
    </div>
  );
};

export default CommandPileItem;
