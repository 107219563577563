import {
  Callout,
  Intent,
  MultiSlider,
  RangeSlider,
  Spinner,
  Button,
  MenuItem,
  DialogBody,
  DialogFooter,
  ControlGroup,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useApiGroundStationService } from 'pages/ops/GSScheduling/context/GroundStationProvider';
import type {
  AntennaOpportunity,
  Window,
  RadioLink,
} from 'api/groundStation/types';
import { durationToReadableFormat } from 'utils/common/dateUtils';
import { DATETIME_FORMAT } from 'constants/datetime';

import s from './bookPassModal.module.scss';
import { useMission } from 'services/Missions';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect2 } from '@blueprintjs/select';
import classNames from 'classnames';
import { Tooltip2 } from '@blueprintjs/popover2';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

const unixToCalendar = (unix: number, format: string) =>
  moment.unix(unix).utc().format(format);

type Props = {
  segments: Window[];
  intent: Intent;
};

// SegmentsSlider - the first component made by Pau Hebrero and the day his career changed forever
// ~ 19/04/2021
const SegmentsSlider = ({ segments, intent }: Props) => {
  const { opportunityInView } = useApiGroundStationService();

  if (opportunityInView === null) {
    return <span>Internal error, opportunity not available</span>;
  }

  return (
    <MultiSlider
      min={moment.utc(opportunityInView.bookingLimits.start).unix()}
      max={moment.utc(opportunityInView.bookingLimits.end).unix()}
      labelRenderer={(value: number) => unixToCalendar(value, 'mm:ss')}
      labelValues={[]}
    >
      {segments.map((segment) => (
        <MultiSlider.Handle
          key={segment.start}
          type="start"
          value={moment.utc(segment.start).unix()}
          intentBefore="none"
        />
      ))}
      {segments.map((segment) => (
        <MultiSlider.Handle
          key={segment.start}
          type="end"
          value={moment.utc(segment.end).unix()}
          intentBefore={intent}
        />
      ))}
    </MultiSlider>
  );
};

const AntennaRow = ({
  antennaAvailability,
}: {
  antennaAvailability: AntennaOpportunity;
}) => {
  const { antennas, setSelectedAntennaAvailability, setSelectedWindow } =
    useApiGroundStationService();

  const currentAntenna = antennas[antennaAvailability.id];

  const chooseUplink = () => {
    setSelectedAntennaAvailability(antennaAvailability);
    setSelectedWindow({
      start: moment
        .utc(antennaAvailability.uplink.segments[0].window.start)
        .unix(),
      end: moment.utc(antennaAvailability.uplink.segments[0].window.end).unix(),
    });
  };

  const chooseDownlink = () => {
    setSelectedAntennaAvailability(antennaAvailability);
    setSelectedWindow({
      start: moment
        .utc(antennaAvailability.downlink.segments[0].window.start)
        .unix(),
      end: moment
        .utc(antennaAvailability.downlink.segments[0].window.end)
        .unix(),
    });
  };

  return (
    <tbody>
      <tr className={s.availabilityRow}>
        <td className={s.antennaColumn}>
          <b>{currentAntenna.name}</b>
        </td>
        <td className={s.typeColumn}>Availability</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.availabilities}
            intent={Intent.SUCCESS}
          />
        </td>
        <td className={s.durationColumn}></td>
      </tr>
      <tr className={s.availabilityRow} onClick={chooseUplink}>
        <td className={s.antennaColumn} />
        <td className={s.typeColumn}>Uplink</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.uplink.segments.map(
              (segment) => segment.window
            )}
            intent={Intent.PRIMARY}
          />
        </td>
        <td className={s.durationColumn}>
          {durationToReadableFormat(antennaAvailability.uplink.duration)}
        </td>
      </tr>
      <tr className={s.availabilityRow} onClick={chooseDownlink}>
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}>Downlink</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.downlink.segments.map(
              (segment) => segment.window
            )}
            intent={Intent.PRIMARY}
          />
        </td>
        <td className={s.durationColumn}>
          {durationToReadableFormat(antennaAvailability.downlink.duration)}
        </td>
      </tr>
      <tr>
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}></td>
        <td className={s.segmentColumn}></td>
        <td className={s.durationColumn}></td>
      </tr>
    </tbody>
  );
};

const PassWindowPickerInfo = () => {
  const { selectedWindow, selectedAntennaAvailability } =
    useApiGroundStationService();

  return (
    <tbody>
      <tr>
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}></td>
        <td className={s.segmentColumn}>
          <table className={s.pickerInformationTable}>
            <tr>
              <th className={s.pickerInformationColumn}>
                <b>From (UTC)</b>
              </th>
              <th className={s.pickerInformationColumn}>
                <b>Max. Elevation (°)</b>
              </th>
              <th className={s.pickerInformationColumn}>
                <b>To (UTC)</b>
              </th>
            </tr>
            <tr>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : unixToCalendar(selectedWindow.start, DATETIME_FORMAT)}
              </td>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : selectedAntennaAvailability?.uplink.maxElevation.toFixed(2)}
              </td>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : unixToCalendar(selectedWindow.end, DATETIME_FORMAT)}
              </td>
            </tr>
          </table>
        </td>
        <td className={s.durationColumn}></td>
      </tr>
    </tbody>
  );
};

const PassWindowPicker = () => {
  const {
    opportunityInView,
    selectedWindow,
    selectedAntennaAvailability,
    antennas,
    setSelectedWindow,
  } = useApiGroundStationService();

  const selectedAntenna = isNil(selectedAntennaAvailability)
    ? '-'
    : antennas[selectedAntennaAvailability.id].name;

  const selectedAntennaCell = opportunityInView?.bookByAntenna ? (
    selectedAntenna
  ) : (
    <Callout icon={IconNames.WARNING_SIGN}>?</Callout>
  );

  let segmentSlider = (
    <Callout icon={IconNames.INFO_SIGN}>
      Please select an initial window by clicking on one of the rows.
    </Callout>
  );
  if (selectedWindow !== null && opportunityInView !== null) {
    segmentSlider = (
      <RangeSlider
        min={moment.utc(opportunityInView.bookingLimits.start).unix()}
        max={moment.utc(opportunityInView.bookingLimits.end).unix()}
        labelRenderer={(value: number) => {
          return unixToCalendar(value, 'mm:ss');
        }}
        labelValues={[]}
        value={[selectedWindow.start, selectedWindow.end]}
        onChange={(value: number[]) => {
          if (
            value[1] - value[0] > opportunityInView.maxPassLength &&
            value[0] !== selectedWindow.start
          ) {
            setSelectedWindow({
              start: value[0],
              end:
                value[1] +
                (opportunityInView.maxPassLength - (value[1] - value[0])),
            });
          } else if (
            value[1] - value[0] > opportunityInView.maxPassLength &&
            value[1] !== selectedWindow.end
          ) {
            setSelectedWindow({
              start:
                value[0] -
                (opportunityInView.maxPassLength - (value[1] - value[0])),
              end: value[1],
            });
          } else {
            setSelectedWindow({ start: value[0], end: value[1] });
          }
        }}
        showTrackFill={true}
        intent={Intent.SUCCESS}
      />
    );
  }

  const duration = isNil(selectedWindow)
    ? '-'
    : durationToReadableFormat(selectedWindow.end - selectedWindow.start);

  return (
    <tr>
      <td className={s.antennaColumn}>{selectedAntennaCell}</td>
      <td className={s.typeColumn}>Booking</td>
      <td className={s.segmentColumn}>{segmentSlider}</td>
      <td className={s.durationColumn}>{duration}</td>
    </tr>
  );
};

const RadioMultiSelect = MultiSelect2.ofType<RadioLink>();

const BookPass = () => {
  const {
    opportunityInView,
    bookPass,
    selectedAntennaAvailability,
    isBookPassProcessing,
    setSelectedRadioLinks,
    selectedRadioLinks,
    selectedProviderDeployment,
    setSelectedProviderDeployment,
  } = useApiGroundStationService();

  const { currentMissionId } = useMission();

  const [availabilities, setAvailabilities] = useState<AntennaOpportunity[]>();

  const { hasPermission: isAllowedToBookPass } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:gs:pass:write',
      id: currentMissionId,
    },
  });

  useEffect(() => {
    const newAntennaOpportunities = isNil(opportunityInView)
      ? ([] as AntennaOpportunity[])
      : opportunityInView.antennas;

    setAvailabilities(newAntennaOpportunities);
  }, [opportunityInView]);

  const radioLinkRenderer: ItemRenderer<RadioLink> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const selected = Boolean(
      selectedRadioLinks.find((srl) => srl.id === item.id)
    );

    return (
      <MenuItem
        key={item.id}
        text={`${item.frequency} (${item.direction})`}
        active={modifiers.active}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
      />
    );
  };

  if (!currentMissionId) {
    return <span>Loading mission...</span>;
  }

  if (!opportunityInView || isBookPassProcessing)
    return (
      <div className={s.container}>
        <Spinner className={s.autoMargine} />
      </div>
    );

  const bookButtonDisabled =
    isNil(selectedAntennaAvailability) ||
    !isAllowedToBookPass ||
    !selectedRadioLinks[0];
  const explanationDisabled = !bookButtonDisabled;
  const disabledButtonMessage = [
    !isAllowedToBookPass
      ? "You don't have sufficient permissions to book a pass."
      : '',
    isNil(selectedAntennaAvailability)
      ? 'The antenna is not available to book a pass.'
      : '',
    !selectedRadioLinks[0] ? 'You must select a radio link' : '',
  ]
    .filter((msg) => msg !== '')
    .join(', ');

  const footerActions = (
    <>
      <ControlGroup fill={false}>
        {selectedAntennaAvailability && (
          <div className="bp4-html-select">
            <select
              onChange={(e) => {
                setSelectedProviderDeployment(e.target.value);
              }}
              value={selectedProviderDeployment}
            >
              {selectedAntennaAvailability.baseband_deployments.map((pd) => (
                <option key={pd} value={pd}>
                  {pd}
                </option>
              ))}
            </select>
            <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
          </div>
        )}
        {selectedAntennaAvailability && (
          <RadioMultiSelect
            className={classNames({
              'border-[1px] border-red-500 box-border':
                selectedRadioLinks.length === 0,
              'w-96': true,
            })}
            items={selectedAntennaAvailability.radio_links}
            itemRenderer={radioLinkRenderer}
            selectedItems={selectedRadioLinks}
            onItemSelect={(item) => {
              if (selectedRadioLinks.find((srl) => srl.id === item.id)) {
                setSelectedRadioLinks((prev) =>
                  prev.filter((p) => p.id !== item.id)
                );
              } else {
                setSelectedRadioLinks((prev) => [...prev, item]);
              }
            }}
            tagRenderer={(item) => (
              <span>{`${item.frequency} (${item.direction})`}</span>
            )}
            placeholder="Select radio links"
            onRemove={(item) => {
              setSelectedRadioLinks((prev) =>
                prev.filter((p) => p.id !== item.id)
              );
            }}
          />
        )}
        <Tooltip2
          disabled={explanationDisabled}
          content={disabledButtonMessage}
          position="bottom"
        >
          <Button
            onClick={bookPass}
            intent={Intent.PRIMARY}
            text="Book"
            icon={IconNames.BOOK}
            disabled={
              isNil(selectedAntennaAvailability) ||
              !isAllowedToBookPass ||
              !selectedRadioLinks[0]
            }
          />
        </Tooltip2>
      </ControlGroup>
    </>
  );

  let message = 'Book selected time range';

  if (!isAllowedToBookPass) {
    message = 'You are not allowed to book passes';
  }

  if (!selectedRadioLinks[0]) {
    message = 'Must select a radio link before booking';
  }

  if (isNil(selectedAntennaAvailability)) {
    message = 'Must select a time range before booking';
  }

  return (
    <>
      <DialogBody>
        <div className={s.availabilityTableContainer}>
          <table className={s.availabilityTable}>
            <thead>
              <tr>
                <th className={s.antennaColumn}>
                  <h3>Antenna</h3>
                </th>
                <th className={s.typeColumn} />
                <th className={s.segmentColumn}>
                  <h3>Opportunities</h3>
                </th>
                <th className={s.durationColumn}>
                  <h3>Duration</h3>
                </th>
              </tr>
            </thead>
            {(availabilities ? availabilities : []).map(
              (antennaAvailability) => (
                <AntennaRow
                  key={antennaAvailability.id}
                  antennaAvailability={antennaAvailability}
                />
              )
            )}
          </table>
        </div>
        <div className={s.availabilityTableContainer}>
          <table className={s.availabilityTable}>
            <PassWindowPickerInfo />
            <PassWindowPicker />
          </table>
        </div>
      </DialogBody>
      <DialogFooter actions={footerActions}>{message}</DialogFooter>
    </>
  );
};

export default BookPass;
