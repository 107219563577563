import React, { useState, useEffect, useCallback, useMemo } from 'react';
import applicationApi from 'datacosmos/services/applicationApi';
import { useAuth } from 'services/auth/AuthWrapper';
import { useRouteMatch } from 'react-router';
import { routeApplicationStatus } from 'datacosmos/components/routePath';
import type {
  IAPIAppValue,
  IApplication,
  IApplicationStatus,
} from 'datacosmos/types/applications';
import { initialApplication } from 'datacosmos/components/Applications/MeasureHeight';
import { MapflowApp } from 'datacosmos/components/Applications/Mapflow';
import { NaxAgricultureApp } from 'datacosmos/components/Applications/SubscriptionApps/NAXAgriculture';
import { EOSOilSpillApp } from 'datacosmos/components/Applications/SubscriptionApps/EOSOilSpill';
import { CarbonSpaceApp } from 'datacosmos/components/Applications/SubscriptionApps/CarbonSpace';
import { QuasarWaterMonitoringApp } from 'datacosmos/components/Applications/SubscriptionApps/QuasarWaterMonitoring';
import { QuasarEcosystemCartographyApp } from 'datacosmos/components/Applications/SubscriptionApps/QuasarEcosystemCartography';
import { QuasarPrecisionAgricultureApp } from 'datacosmos/components/Applications/SubscriptionApps/QuasarPrecisionAgriculture';
import { QuasarMaritimeMonitoringApp } from 'datacosmos/components/Applications/SubscriptionApps/QuasarMaritimeMonitoring';
import { QuasarWildFiresApp } from 'datacosmos/components/Applications/SubscriptionApps/QuasarWildFires';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { ApplicationCatalogContext } from 'datacosmos/stores/ApplicationCatalogContext';
import { cropImageApp } from 'datacosmos/components/Applications/CropImage/CropImage';
import { DATACOSMOS_APPLICATION_SUITE } from 'env';
import { TOAApp } from 'datacosmos/components/Applications/CondataApps/TOA';
import { Perusat1IngestionApp } from 'datacosmos/components/Applications/CondataApps/Perusat1Ingestion';
import { ChangeDetectionApp } from 'datacosmos/components/Applications/CondataApps/ChangeDetection';
import { SPOTIngestionApp } from 'datacosmos/components/Applications/CondataApps/SPOTIngestion';
import { TerraSATXIngestionApp } from 'datacosmos/components/Applications/CondataApps/TerraSATXIngestion';
import { CosmoSkymedIngestionApp } from 'datacosmos/components/Applications/CondataApps/COSMOskymeedIngestion';
import { PLEIADESIngestionApp } from 'datacosmos/components/Applications/CondataApps/PLEIADESIngestion';
import { KompSat3IngestionApp } from 'datacosmos/components/Applications/CondataApps/Kompsat3Ingestion';
import { KazEOSATIngestionApp } from 'datacosmos/components/Applications/CondataApps/KazEOSATIngestion';
import { SupervisedClassificationApp } from 'datacosmos/components/Applications/CondataApps/SupervisedClassification';
import { ShiftImageApplication } from 'datacosmos/components/Applications/ShiftImage/ShiftImage';
import { OrthorectificationApp } from 'datacosmos/components/Applications/CondataApps/Orthorectification';
import { CoregistrationApp } from 'datacosmos/components/Applications/CondataApps/Coregistration';
import { UnsupervisedClassificationApp } from 'datacosmos/components/Applications/CondataApps/UnsupervisedClassification';
import { BOAApp } from 'datacosmos/components/Applications/CondataApps/BOA';
import { LandsatIngestionApp } from 'datacosmos/components/Applications/CondataApps/LandsatIngestion';
import { SentinelIngestionApp } from 'datacosmos/components/Applications/CondataApps/SentinelIngestion';
import { CbersIngestionApp } from 'datacosmos/components/Applications/CondataApps/CbersIngestion';
import { AsterIngestionApp } from 'datacosmos/components/Applications/CondataApps/AsterIngestion';
import { ModisIngestionApp } from 'datacosmos/components/Applications/CondataApps/ModisIngestion';
import { MissingPixelsApp } from 'datacosmos/components/Applications/CondataApps/MissingPixels';
import { NOAAIngestionApp } from 'datacosmos/components/Applications/CondataApps/NOAAIngestion';

const useApplicationCatalogProvider = () => {
  // These are the apps that are available on the Datacosmos platform
  const OpencosmosApps = useMemo(
    () => [
      initialApplication,
      MapflowApp,
      NaxAgricultureApp,
      EOSOilSpillApp,
      CarbonSpaceApp,
      QuasarWaterMonitoringApp,
      QuasarEcosystemCartographyApp,
      QuasarPrecisionAgricultureApp,
      QuasarMaritimeMonitoringApp,
      QuasarWildFiresApp,
      ShiftImageApplication,
    ],
    []
  );

  // These are the apps that are available on the Condata platform
  const ConidaApps = useMemo(
    () => [
      cropImageApp,
      TOAApp,
      BOAApp,
      ChangeDetectionApp,
      Perusat1IngestionApp,
      SPOTIngestionApp,
      TerraSATXIngestionApp,
      PLEIADESIngestionApp,
      CosmoSkymedIngestionApp,
      KompSat3IngestionApp,
      KazEOSATIngestionApp,
      LandsatIngestionApp,
      SentinelIngestionApp,
      CbersIngestionApp,
      AsterIngestionApp,
      ModisIngestionApp,
      NOAAIngestionApp,
      SupervisedClassificationApp,
      OrthorectificationApp,
      CoregistrationApp,
      UnsupervisedClassificationApp,
      MissingPixelsApp,
    ],
    []
  );

  /**
   * Front end applications
   * Register in this array the applications that run on the front-end.
   * This will be the initial apps on the apps, the backend apps will be appended
   * after we query the backend.
   */

  const FrontEndApps = useMemo(
    () =>
      DATACOSMOS_APPLICATION_SUITE === 'conida' ? ConidaApps : OpencosmosApps,
    [ConidaApps, OpencosmosApps]
  );

  const { authBackend, token, checkPermissions } = useAuth();

  const applicationStatusRouteMatch = useRouteMatch<string>(
    routeApplicationStatus
  );
  const applicationStatus = applicationStatusRouteMatch?.params;

  const [applicationList, setApplicationList] =
    useState<IApplication[]>(FrontEndApps);

  const [applicationStatusResult, setApplicationStatusResult] =
    useState<IApplicationStatus[]>();

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [subscribeApplicationName, setSubscribeApplicationName] = useState('');

  const [applicationAOIs, setApplicationAOIs] = useState<
    GeoJSON.Polygon[] | undefined
  >(undefined);

  const [installedApps, setInstalledApps] = useLocalStorage<IApplication[]>(
    'installedApps',
    []
  );

  const [selectedInstalledApp, setSelectedInstalledApp] =
    useState<IApplication>();

  const [shouldAutoOpen, setShouldAutoOpen] = useState<boolean>(false);

  const [isAllowedToAccessApplications, setIsAllowedToAccessApplications] =
    useState<boolean>(false);

  const openSubscribeModal = useCallback((applicationName: string) => {
    setIsSubscribeModalOpen(true);
    setSubscribeApplicationName(applicationName);
  }, []);

  const closeSubscribeModal = useCallback(() => {
    setIsSubscribeModalOpen(false);
    setSubscribeApplicationName('');
  }, []);

  const setInputData = useCallback(
    (applicationToSet: string, values: { [key: string]: IAPIAppValue }) => {
      setApplicationList((prev) =>
        prev.map((app) => {
          if (app.name === applicationToSet) {
            app.values = { ...app.values, ...values };
          }
          return app;
        })
      );
    },
    []
  );

  const queryApplicationStatus = useCallback(async () => {
    const { fetchApplicationStatus } = applicationApi(token);
    setApplicationStatusResult(await fetchApplicationStatus());
  }, [token]);

  useEffect(() => {
    if (authBackend === undefined) {
      return;
    }
    if (
      applicationStatus &&
      applicationStatusRouteMatch &&
      applicationStatusRouteMatch.isExact
    ) {
      void queryApplicationStatus();
    }
  }, [
    applicationStatus,
    applicationStatusRouteMatch,
    authBackend,
    queryApplicationStatus,
  ]);

  const toggleAppInstall = useCallback(
    (app: IApplication) => {
      setInstalledApps((prev) => {
        const foundApp = prev.find((a) => a.id === app.id);
        if (foundApp) {
          return prev.filter((a) => a.id !== app.id);
        } else {
          return [...prev, app];
        }
      });
    },
    [setInstalledApps]
  );

  const toggleInstalledAppOpen = useCallback((app: IApplication) => {
    setSelectedInstalledApp((prev) => {
      return prev?.id === app.id ? undefined : app;
    });
  }, []);

  const getInstalledStatus = useCallback(
    (app: IApplication): boolean => {
      return Boolean(installedApps.find((a) => a.id === app.id));
    },
    [installedApps]
  );

  useEffect(() => {
    setApplicationList([...FrontEndApps]);
  }, [FrontEndApps]);
  useEffect(() => {
    // All Opencosmos users can access applications
    if (DATACOSMOS_APPLICATION_SUITE !== 'conida') {
      setIsAllowedToAccessApplications(true);
      return;
    }

    /* Conida advanced users will have this permission and
     only they can access applications */
    const getApplicationsAccess = async () => {
      const hasPerms = await checkPermissions({
        type: 'global',
        actionScope: 'data:app:execute',
      });

      setIsAllowedToAccessApplications(hasPerms);
      if (!hasPerms) {
        setInstalledApps([]);
      }
    };

    void getApplicationsAccess();
  }, [checkPermissions, setInstalledApps]);
  return {
    applicationList,
    setInputData,
    applicationStatusResult,
    queryApplicationStatus,
    isSubscribeModalOpen,
    subscribeApplicationName,
    openSubscribeModal,
    closeSubscribeModal,
    applicationAOIs,
    setApplicationAOIs,
    installedApps,
    toggleAppInstall,
    getInstalledStatus,
    toggleInstalledAppOpen,
    selectedInstalledApp,
    setSelectedInstalledApp,
    setShouldAutoOpen,
    shouldAutoOpen,
    isAllowedToAccessApplications,
  };
};

export type UseApplicationCatalogProvider =
  typeof useApplicationCatalogProvider;

export const ApplicationCatalogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ApplicationCatalogContext.Provider value={useApplicationCatalogProvider()}>
      {children}
    </ApplicationCatalogContext.Provider>
  );
};
