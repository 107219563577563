import {
  Button,
  ButtonContext,
  ButtonProps,
  useContextProps,
} from "react-aria-components";
import { tv, VariantProps } from "tailwind-variants";
import Icon, { IconName } from "../../icons/Icon";
import React from "react";
import Spinner from "../Spinner/Spinner";

const button = tv({
  base: "max-h-8 p-1 inline-flex gap-1 items-center justify-center whitespace-nowrap",
  variants: {
    intent: {
      primary: "",
      warning: "",
      secondary: "",
    },
    isMinimal: {
      true: "",
      false: "border",
    },
    isTransparent: {
      true: "p-0 hover:bg-neutral-200 dark:hover:bg-item-dark-hover dark:text-neutral",
      false: "",
    },
    isDisabled: {
      true: "cursor-not-allowed opacity-50 ",
      false: "",
    },
    size: {
      sm: "text-sm px-1",
      base: "text-base px-1.5",
      lg: "text-lg px-2",
    },
    fill: {
      true: "w-full",
      false: "w-fit",
    },
  },
  compoundVariants: [
    {
      intent: "primary",
      isDisabled: false,
      isMinimal: false,
      isTransparent: false,
      class: [
        "text-item-contrast bg-accent border-accent hover:bg-item-hover",
        "dark:text-item-dark-contrast dark:hover:bg-item-dark-hover",
      ],
    },
    {
      intent: "secondary",
      isDisabled: false,
      isMinimal: false,
      isTransparent: false,
      class: [
        "text-item-contrast bg-neutral border border-neutral-500 hover:bg-item-hover",
        "dark:text-item-dark-contrast dark:border-neutral-700 dark:bg-neutral-800 dark:hover:bg-item-dark-hover",
      ],
    },
    {
      intent: "warning",
      isDisabled: false,
      isMinimal: false,
      isTransparent: false,
      class: [
        "text-item-contrast bg-warning-200 hover:bg-warning-300",
        "dark:text-item-dark-contrast dark:bg-warning-700 dark:hover:bg-warning-600",
      ],
    },

    {
      intent: "primary",
      isDisabled: false,
      isMinimal: true,
      isTransparent: false,
      class: [
        "text-item-contrast bg-accent-300 hover:bg-item-hover",
        "dark:text-item-dark-contrast dark:bg-accent-600 dark:hover:bg-item-dark-hover",
      ],
    },
    {
      intent: "secondary",
      isDisabled: false,
      isMinimal: true,
      isTransparent: false,
      class: [
        "text-item-contrast bg-neutral hover:bg-neutral-300 hover:bg-item-hover",
        "dark:text-item-dark-contrast dark:bg-neutral-800 dark:hover:bg-item-dark-hover",
      ],
    },
    {
      intent: "warning",
      isDisabled: false,
      isMinimal: true,
      isTransparent: false,
      class: [
        "text-item-contrast bg-neutral border border-warning-400 hover:bg-warning-300",
        "dark:text-item-dark-contrast dark:bg-neutral-800 border dark:border-warning-500 dark:hover:bg-warning-400",
      ],
    },
  ],
  defaultVariants: {
    intent: "secondary",
    isMinimal: false,
    isDisabled: false,
    isTransparent: false,
    size: "sm",
  },
});

export type Props = ButtonProps &
  VariantProps<typeof button> & {
    iconPlacement?: "right" | "left";
    icon?: IconName;
  } & {
    fill?: boolean;
    loading?: boolean;
  };

export type ButtonIconSize = "sm" | "lg" | "base";

const iconSizemap = {
  sm: 12,
  base: 16,
  lg: 24,
} as const;

const MyButton = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
    [props, ref] = useContextProps(props, ref, ButtonContext);

    const iconSize = iconSizemap[props.size ?? "sm"];
    const iconPlacement = props.iconPlacement ?? "right";
    const iconClassName = props.isDisabled
      ? "stroke-item-contrast-inactive dark:stroke-item-dark-contrast-inactive"
      : "stroke-item-contrast";
    let children = props.children;
    if (typeof props.icon !== "undefined") {
      children = (
        <>
          {iconPlacement === "left" && (
            <Icon icon={props.icon} size={iconSize} className={iconClassName} />
          )}
          {props.children}
          {iconPlacement === "right" && (
            <Icon icon={props.icon} size={iconSize} className={iconClassName} />
          )}
        </>
      );
    }

    return (
      <Button
        {...props}
        ref={ref}
        className={(params) => {
          let p = {
            ...params,
            isMinimal: props.isMinimal,
            size: props.size,
            intent: props.intent,
            fill: props.fill,
            isTransparent: props.isTransparent,
          };

          return button({
            ...p,
            class:
              typeof props.className === "function"
                ? props.className(p)
                : props.className,
          });
        }}
        children={props.loading ? <Spinner size={16} /> : children}
      />
    );
  }
);

export default MyButton;
